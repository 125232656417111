import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UnsignedContract } from '../../administration.models';

@Component({
  selector: '[app-single-signable-contract]',
  templateUrl: './single-signable-contract.component.html',
  styleUrls: ['./single-signable-contract.component.css']
})
export class SingleSignableContractComponent implements OnInit {

  @Input() contract: UnsignedContract
  @Input() listChanged: BehaviorSubject<boolean>;
  isLoading = false;
  constructor(    @Inject('BASE_URL') private baseUrl: string,
  private http: HttpClient) { }

  ngOnInit() {
  }

  confirmContract(userId: string)
  {
    this.isLoading = true;
    this.http.post(this.baseUrl + "Contract/ConfirmContract", {applicationUserId: userId})
    .pipe(finalize(() => this.isLoading = false))
      .subscribe(() => this.listChanged.next(true));
  }

  openDocument(id: number, name: string) {
    const upload$ = this.http.get(this.baseUrl + "Contract/GetContract/" + id.toString(), { responseType: "arraybuffer" });
    upload$.subscribe(data => {
        var file = new Blob([data]);
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        this.downloadBlob(file, name);
    })
  }

  downloadBlob(blob, name = 'file.txt') {
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
}

}

