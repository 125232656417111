<div class="container-fluid page-container">
  <div class="row">
    <div class="col-12">
      <h6 class="text-center">Par darījuma norisi</h6>
      <h1 class="text-center">Kā mēs strādājam</h1>
    </div>

    <div class="grey-container">
      <div class="col-12 col-md-5 m-auto">
        <h3 class="text-center">1. Pārbaude un izvērtēšana</h3>
        <p class="text-center">Tiek novērtēta mežsaimniecisko darbu nepieciešamība un iespējamība, balstoties uz meža
          inventarizācijas datiem.</p>
      </div>
    </div>

    <div class="col-12 col-md-5 m-auto">
      <h3 class="text-center">2. Meža īpašuma apsekošana</h3>
      <p class="text-center">Kopā ar īpašnieku apsekojam meža īpašumu dabā, lai izprastu tā specifiku un apspriestu
        īpašnieka vēlmes, kā arī identificētu potenciālus izaicinājumus.</p>
    </div>

    <div class="grey-container">
      <div class="col-12 col-md-5 m-auto">
        <h3 class="text-center">3. Izsoles objekta sagatavošana</h3>
        <p class="text-center">Tiek veikta cirsmu iezīmēšana un novērtējums (stigošana, dastošana), lai izsoles
          dalībnieki
          saņemtu precīzu informāciju par pārdošanai piedāvātajiem izsoles objekta resursiem.</p>
      </div>
    </div>

    <div class="col-12 col-md-5 m-auto">
      <h3 class="text-center">4. Oficiāls apstiprinājums</h3>
      <p class="text-center">Saņemts apstiprinājums no Valsts meža dienesta par mežsaimniecisko darbu saskaņošanu, lai
        nodrošinātu atbilstību normatīvajiem aktiem.</p>
    </div>

    <div class="grey-container">
      <div class="col-12 col-md-5 m-auto">
        <h3 class="text-center">5. Pievešanas ceļu un krautuves vietu saskaņošana</h3>
        <p class="text-center">Tiek noteikti un saskaņoti ceļi un krautuves vietas, lai nodrošinātu efektīvu materiālu
          transportēšanu.</p>
      </div>
    </div>

    <div class="col-12 col-md-5 m-auto">
      <h3 class="text-center">6. Izsoles uzsākšana</h3>
      <p class="text-center">Tiek noteikta izsoles objekta sākuma cena, pamatojoties uz novērtējumu un tirgus analīzi
        un izsole tiek oficiāli uzsākta, potenciālie pircēji tiek informēti par iespēju piedalīties.</p>
    </div>

    <div class="grey-container mb-0">
      <div class="col-12 col-md-5 m-auto">
        <h3 class="text-center">7. Objekta pārdošana</h3>
        <p class="text-center">Pēc izsoles noslēguma tiek organizēta līguma parakstīšanas ar izsoles uzvarētāju.</p>
      </div>
    </div>
  </div>
</div>