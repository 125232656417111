import { LoginComponent } from './../user/login/login.component';
import { ApplicationUser } from './../shared/user.models';
import { UserService } from './../shared/user.service';
import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import smoothscroll from 'smoothscroll-polyfill';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  @ViewChild('navBarToggler') navBarToggler : ElementRef<HTMLInputElement>;
  isActive = false;
  isRootRoute = true;
  modalRef?: BsModalRef;
  notificationCount = 0;
  isDropdownOpen: boolean = false;
  canAccessAdminPanel: boolean = false;
  currentApplicationUser: ApplicationUser;
  userSubscription: Subscription = Subscription.EMPTY;
  routeSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    smoothscroll.polyfill();

    this.userSubscription = this.userService.applicationUserSubject
      .subscribe(user => this.updateUserNavBarSettings(user));

    this.routeSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.isRootRoute = event.url === '/' && event.urlAfterRedirects === '/';
        this.SetNavMenuState();
      });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  navigateToOwnerSection(section: string, isMobile = false) {
    this.router.navigate(['/']);
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(section);
    }, 100);

    this.collapseMobileMenu();
  }  

  toggleDropdown(open: boolean): void {
    this.isDropdownOpen = open;
  }

  @HostListener('window:scroll', [])
  SetNavMenuState(): void {
    this.isActive = (!this.isRootRoute || (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > 50) && window.innerWidth > 1023;
  }

  getNotificationCount() {
    this.userService.getNotificationCount()
      .pipe(
        finalize(() => { })
      )
      .subscribe(data => {
        this.notificationCount = data;
      },
      err => console.log());
  }

  openLoginModal(): void {
    this.collapseMobileMenu();
    this.modalRef = this.modalService.show(LoginComponent,
      { class: 'registration-modal' });
  }

  logout(): void {
    this.userService.logout();
  }

  navigate(path: string): void {
    // Already clicked path scroling to top
    if (this.router.url === path) {
      this.viewportScroller.scrollToPosition([0,0]);
      this.collapseMobileMenu();
      return;
    }

    this.router.navigate([path]);

    // Scrolling instantly to top
    document.documentElement.style.scrollBehavior = "auto";
    document.body.scrollTop = 0;
    setInterval(() => document.documentElement.style.scrollBehavior = "smooth", 50);
    
    this.collapseMobileMenu();
  }

  private collapseMobileMenu() {
    if (window.innerWidth < 1024) {
      this.navBarToggler.nativeElement.checked = false;
    }
  }

  private updateUserNavBarSettings(user: ApplicationUser) {
    this.currentApplicationUser = user;

    if (user) {
      this.canAccessAdminPanel = user.role != null && user.role.includes('CanAccessAdminPanel');
      this.getNotificationCount();
      return;
    }
    this.canAccessAdminPanel = false;
  }
}