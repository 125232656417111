<div class="card" [class]="status" (click)="navigate($event)">
  <span class="badge active" *ngIf="status === 'active'">Aktīva</span>
  <p *ngIf="ended" class="dark-green text-center auction-status-ended"><strong>Izsole ir noslēgusies!</strong></p>
  <p class="text-center mt-4 mb-0"><strong>{{property.auctionType.name}}</strong></p>
  <div class="card-header">
    <img [src]="thumbnailUrl" alt="Meža izsole">
    <ng-container *ngIf="!subscriptionLoading; else loading">
      <span class="icon-heart" [ngClass]="property.isSubscribed ? 'active' : ''" (click)="subscribeToAuction(property.id)"></span>
    </ng-container>
    <ng-template #loading>
      loading
    </ng-template>
  </div>
  <div class="card-body">
    <h4 class="text-center">{{property.propertyName}}</h4>
    <p class="text-center">{{property.region.name}}</p>

    <p class="text-center pt-0 mb-0" style="font-size: 12px" *ngIf="property.auctionType.id === 2"><strong>Kopējā platība {{property.propertyArea}} </strong></p>
    <p class="text-center pt-0" style="font-size: 12px" *ngIf="property.auctionType.id === 2"><strong>Meža platība {{property.forestArea}} </strong></p>
    <p class="text-center pt-0 mb-0" style="font-size: 12px" *ngIf="property.auctionType.id === 1"><strong>Cirmas kopējā platība {{property.propertyArea}} </strong></p>
    <p class="text-center pt-0" style="font-size: 12px"*ngIf="totalTrees > 0 && property.auctionType.id === 1"><strong>Izcērtamais apjoms {{totalTrees | number: '1.2-2'}} m<span style="font-size: 12px; position: relative; top:-3px;">3</span></strong></p>
    <div class="auction-details">
      <h6 class="text-center mb-0"><strong>€{{maxBid | number: '1.2-2'}}</strong></h6>
    </div>
    <p class="text-center auction-count">({{bidCountWording}})</p>
    <p class="text-center pt-1 mb-0" style="font-size: 12px"><strong>Beigu datums {{property.auctionEnds | date:'dd.MM.yyyy hh:mm'}} </strong></p>
    <!-- <div class="auction-dates-info-container">
      <div class="auction-dates-info-icon">
        <span class="card-img-top icon-icon-clock"></span>
      </div>
      <div class="auction-dates-info" >
        <p class="dark-green">Noslēgsies {{auctionEndDate | date: 'dd/MM/yyyy'}}</p>
        <p class="dark-green"><strong>Plkst. {{auctionEndDate | date: 'hh:mm:ss'}}</strong></p>
      </div>
     
    </div> -->
    <!-- <p class="text-center dark-green mb-0 mt-3" style="line-height: 15px;"><strong>{{property.propertyName}}</strong></p>
    <p class="text-center dark-green mb-0" style="line-height: 15px;">{{property.county.name}}, {{property.region.name}}</p> -->
  </div>