import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NewsArticle, PaginatedResponse } from '../administration.models';

@Injectable()
export class ArticleService {
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
    ) { }

    // REGIONS
    getAllArticles(): Observable<NewsArticle[]> {
        return this.http.get<NewsArticle[]>(this.baseUrl + 'Article/All')
    }

    getArticle(id: number): Observable<NewsArticle> {
        return this.http.get<NewsArticle>(this.baseUrl + 'Article/' + id)
    }

    GetPaginatedArticles(page: number, pageSize: number): Observable<PaginatedResponse<NewsArticle>> {
        const params = new HttpParams()
          .set('page', page.toString())
          .set('pageSize', pageSize.toString());
    
          return this.http.get<PaginatedResponse<NewsArticle>>(this.baseUrl + 'Article/GetPaginatedArticles', { params });
      }
}
