<p>
  <a class="nav-link" [routerLink]="['create']">Pievienot jaunu</a>

 
</p>

<table>
  <tr *ngFor="let article of articles">
    <td>{{article.id}}</td>
    <td>Apskatīt</td>
  </tr>
</table>
