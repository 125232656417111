<div class="container page-container">
  <div class="row">
    <div class="col-8 m-auto">
      <h1 class="title text-center">Vai tu vēlies savu mežu pārdot par VISLABĀKO cenu?</h1>
      <div class="d-flex justify-content-between w-70">
        <button  class="btn btn-primary" onclick="buttonClicked(1)">Nē, vēlos atdot pa lēto</button>
        <button  class="btn btn-primary" onclick="buttonClicked(1)">Vēlos saņemt labāko</button>
      </div>
    </div>
  </div>
</div>