<label>Failu saraksts: </label>
<br>

<ng-container *ngFor="let file of files">
  <div class="row">
    <div class="col-6">
      <p (click)="down(file.id)">
        
        {{file.fileName}}
      </p>
    </div>
    <div class="col-2">
      <button type="button" class="btn btn-primary" (click)="delete(file.id)">Dzēst</button>
    </div>
  </div>
  <br>
</ng-container>


<div class="custom-file">
  <input type="file" class="custom-file-input" id="validatedCustomFile" [accept]="requiredFileType"
  (change)="onFileSelected($event)" #fileUpload>
  <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
  <div class="invalid-feedback">Example invalid custom file feedback</div>
</div>


<!-- <div class="file-upload">

   {{fileName || "No file uploaded yet."}}

    <button mat-mini-fab color="primary" class="upload-btn"
      (click)="fileUpload.click()">att

    </button>

</div> -->

<!-- <div class="progress"> -->
<!-- 
  <mat-progress-bar class="progress-bar" mode="determinate"
                    [value]="uploadProgress" *ngIf="uploadProgress">

  </mat-progress-bar>

  <mat-icon class="cancel-upload" (click)="cancelUpload()" 
            *ngIf="uploadProgress">delete_forever</mat-icon> -->
<!-- 
</div>

<button type="button" (click)="down()">Download</button> -->