<div class="modal-header">
  <h4 class="modal-title pull-left">Labot Tulkojumu</h4>
  <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="languageForm" (ngSubmit)="submitForm()">

    <div class="form-group">
      <label for="languageText" class="mr-1">Valoda: </label> <strong>{{languageForm.controls['language'].value}}</strong>
    </div>

    <div class="form-group">
      <label for="languageText" class="mr-1">Konstante: </label> <strong>{{languageForm.controls['constant'].value}}</strong>
    </div>


    <div class="form-group">
      <label for="languageText">Tulkojums<span class="text-danger">*</span></label>
      <textarea type="languageText" class="form-control" id="languageText" formControlName="languageText"
        [class.is-invalid]="!languageForm.controls['languageText'].valid && languageForm.controls['languageText'].touched">
      </textarea>
      <span class="text-danger"
        *ngIf="!languageForm.controls['languageText'].valid && languageForm.controls['languageText'].touched">
        Tulkojumam nosaukums jābūt aizpildītam!
      </span>
    </div>

    <div class="d-md-flex justify-content-between">
      <ng-container *ngIf="!isLoading; else loading">
        <button type="submit" class="btn btn-primary">
          Saglabāgt
        </button>
      </ng-container>
    </div>
  </form>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>