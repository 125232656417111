import { CabinetModule } from './cabinet/cabinet.module';
import { AdminModule } from './admin/admin.module';
import { HomeModule } from './home/home/home.module';
import { HowDidYouGetHereComponent } from './shared/how-did-you-get-here/how-did-you-get-here.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { UserService } from './shared/user.service';
import { RegistrationComponent } from './user/registration/registration.component';
import { LoginComponent } from './user/login/login.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxView360Module } from '@egjs/ngx-view360';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import {} from '@fortawesome/fontawesome-free';

// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';
import { TranslationService } from './shared/translation.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslationLoader } from './shared/translationLoader';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { AuctionModule } from './auction/auction.module';
import { FooterComponent } from './footer/footer.component';
import { UnauthorizedComponent } from './Unauthorized/Unauthorized.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AllAuctionsComponent } from './all-auctions/all-auctions.component';
import { ArticlesModule } from './articles/articles.module';
import { ToasterModule } from './shared/toaster/toaster.module';
import { AuctionRegulationComponent } from './static-pages/auction-regulation/auction-regulation.component';
import { PrivacyPolicyComponent } from './static-pages/privacy-policy/privacy-policy.component';
import { DocumentationComponent } from './static-pages/documentation/documentation.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { FirstTimeOfferComponent } from './static-pages/first-time-offer/first-time-offer.component';
import { HowWeWorkComponent } from './static-pages/how-we-work/how-we-work.component';

export function loadCrucialData() {
    return function() {
      // or use UserService
      return delay(1000);
    }
  }
  
  export function delay(delay: number) {
    return function() {
      return new Promise(function(resolve) {
          setTimeout(resolve, delay);
      });
    }
  }

@NgModule({
    declarations: [			
        AppComponent,
        NavMenuComponent,
        CookieBannerComponent,
        RegistrationComponent,
        LoginComponent,
        HowDidYouGetHereComponent,
        SessionExpiredComponent,
        FooterComponent,
        UnauthorizedComponent,
        ContactsComponent,
        AuctionRegulationComponent, 
        AllAuctionsComponent,
        PrivacyPolicyComponent,
        HowWeWorkComponent,
        FirstTimeOfferComponent
   ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        BrowserAnimationsModule,
        ToasterModule,
        BsDropdownModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        ReactiveFormsModule,
        HomeModule,
        AdminModule,
        NgxView360Module,
        CabinetModule,
        AuctionModule,
        ArticlesModule,
        RouterModule.forRoot([
            { path: '', component: HomeModule, pathMatch: 'full' },
            { path: 'registration', component: RegistrationComponent },
            { path: 'session-expired', component: SessionExpiredComponent },
            { path: 'contacts', component: ContactsComponent },
            { path: 'auctions', component: AllAuctionsComponent },
            { path: 'admin', component: AdminModule, canActivate: [AuthGuard], data:{permittedRoles: ['CanAccessAdminPanel']} },
            { path: 'how-did-you-get-here', component: HowDidYouGetHereComponent },
            { path: 'unauthorized', component: UnauthorizedComponent },
            { path: 'auction-regulation', component: AuctionRegulationComponent},
            { path: 'privacy-policy', component: PrivacyPolicyComponent},
            { path: 'how-we-work', component: HowWeWorkComponent},
            { path: 'documentation', component: DocumentationComponent}
        ],{scrollPositionRestoration: 'enabled'}),
    ],
    providers: [
        TranslationService,
        UserService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: loadCrucialData()
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslationLoader {
  return new TranslationLoader(http);
}