import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserLogin, UserRegistrationModel, ApplicationUser, DecodedJWTUser } from './user.models';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { CompanyLookupDto } from '../models/all.models';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  http: HttpClient;
  url: string;
  applicationUserSubject: BehaviorSubject<ApplicationUser> = new BehaviorSubject<ApplicationUser>(null);
  applicationUser: ApplicationUser;
constructor(
  http: HttpClient, 
  @Inject('BASE_URL') baseUrl: string,
  private router: Router
) {
  this.http = http;
  this.url = baseUrl;
  this.applicationUserSubject.subscribe(user => {
    this.applicationUser = user;
  })
 }

emitSameUserValue(){
  this.applicationUserSubject.next(this.applicationUser);
}
register(user: UserRegistrationModel): Observable<any> {
  return this.http.post<any>(this.url + 'User/Register', user);
}

getNotificationCount(): Observable<number> {
  return this.http.get<number>(this.url + 'User/GetNotificationCount');
}

getUserNotifications(): Observable<any> {
  return this.http.get<any>(this.url + 'User/GetUserNotifications');
}
resendConfirmationEmail(): Observable<boolean> {
  return this.http.get<boolean>(this.url + 'User/ResendConfirmationEmail');
}

confirmEmailAddress(validationCode :string): Observable<boolean> {
  return this.http.post<boolean>(this.url + 'User/ConfirmEmailAddress', validationCode);
}

lookupCompany(regNo: string): Observable<CompanyLookupDto> {
  return this.http.get<CompanyLookupDto>(this.url + 'CompanyLookUp/' + regNo);
}

getMyAccountAuctions(): Observable<any> {
  return this.http.get<any>(this.url + 'Auction/GetMyAccountAuctions');
}

getUser(): Observable<any> {
  return this.http.get<any>(this.url + 'User/RefreshToken')
  .pipe(
    tap(data => {
      if(data !== null) {
        const decodedData = this.decodeAccessTocken(data.token);
        this.applicationUserSubject.next(new ApplicationUser(decodedData));
        // this.router.navigateByUrl('/');
      }
    })
  );
  
}

login(user: UserLogin): Observable<any> {
  return this.http.post<any>(this.url + 'User/Login', user )
  .pipe(
    tap(data => {
      if(data !== null) {
        localStorage.setItem('token', data.token);
        const decodedData = this.decodeAccessTocken(data.token);
        this.applicationUserSubject.next(new ApplicationUser(decodedData));
        this.router.navigateByUrl('/');
      }
    })
  );
}

loginFromToken(token: string) {
  const decodedData = this.decodeAccessTocken(token);
  this.applicationUserSubject.next(new ApplicationUser(decodedData));
}

 logout() {
  this.applicationUserSubject.next(null);
  localStorage.removeItem('token')
  this.router.navigateByUrl('/');
 }

decodeAccessTocken(token: string): DecodedJWTUser {
  try{
      return jwt_decode(token);
  }
  catch(Error){
      return null;
  }
}

userHasRoles(requiredRoles: string[]): boolean {
  const roleSatisfied: boolean[] = [];
  if(this.applicationUser === null) {
    return false;
  }

  if (this.applicationUser.role === null) {
    return false;
  }

  if (this.applicationUser.role.length === 0) {
    return false;
  }

  requiredRoles.forEach(role => {
    roleSatisfied.push(this.applicationUser.role.includes(role));
  }); 
  return !roleSatisfied.includes(false);
}


// roleMatch(allowedRoles): boolean {
//   let isMatch = false;
//   var payload = JSON.parse()
// }
  
}
