import { PropertyService } from './../../property/property.service';
import { Component, OnInit } from '@angular/core';
import { County } from '../../administration.models';
import { finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NewCountyModalComponent } from '../new-region-modal/new-county-modal.component';

@Component({
  selector: 'app-counties',
  templateUrl: './counties.component.html',
  styleUrls: ['./counties.component.css']
})
export class CountiesComponent implements OnInit {
  counties: County[] = [];
  isLoading = false;

  constructor(
    private modalService: BsModalService,
    private propertyService: PropertyService) { }

  modalRef?: BsModalRef;
  ngOnInit() {
    this.getCounties();
  }

  getCounties() {
    this.isLoading = true;
    this.propertyService.getAllCounties()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe( x => this.counties = x )
  }

  openNewCountyModal(): void {
    this.modalRef = this.modalService.show(NewCountyModalComponent,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getCounties();
    });
  }
}
