<div class="modal-header">
  <h4 class="modal-title pull-left">Ienākt sistēmā</h4>
  <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="row">
      <div class="col-12">
        <div class="form-group text-left">
          <span class="text-danger text-left" *ngIf="wrongPass">Nepareizs lietotājvārds vai parole <br></span>

          <label for="userName">Lietotājvārds</label>
          <input type="text" class="form-control" name="userName" formControlName="userName"
            [class.is-invalid]="!loginForm.controls['userName'].valid && loginForm.controls['userName'].touched">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group text-left">
          <label for="password">Parole</label>
          <input type="password" class="form-control" name="password" formControlName="password"
            [class.is-invalid]="!loginForm.controls['password'].valid && loginForm.controls['password'].touched">
        </div>
      </div>
    </div>
    <ng-container *ngIf="!isLoading; else loading">

      <div class="d-md-flex justify-content-md-between mt-4">
        <div class="order-lg-2 order-md-2 order-2 p-0">
          <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">Ienākt</button>
        </div>
        <div class="col-12 col-md-6 order-lg-1 order-md-1 order-1 p-0 mt-md-0 mt-5">
          <p><a class="cursor-pointer" (click)="closeModal()" [routerLink]="['/registration']">Izsoles dalībnieka konta izveide (pircējiem)</a></p>
        </div>
      </div>
    </ng-container>
  </form>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="spiner-container">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>