<h2>Rediģēt īpašumu</h2>
<form [formGroup]="propertyForm" (ngSubmit)="submitForm()">
  <ng-container *ngIf="!isPropertyLoading; else loading">
    <div class="row">
      <div class="col-5">
        <div class="form-group">
          <label for="auctionTypeId">Izsoles tips (nevar mainīt)</label>
          <ng-container *ngIf="!auctionTypeIsLoading else loading">
            <select class="form-control" id="auctionTypeId" name="auctionTypeId" formControlName="auctionTypeId"
              [class.is-invalid]="!propertyForm.controls['auctionTypeId'].valid && propertyForm.controls['auctionTypeId'].touched" readonly>
              <option *ngFor="let auctType of auctionTypes" [ngValue]="auctType.id">{{auctType.name}}</option>
            </select>
            <span class="text-danger"
              *ngIf="!propertyForm.controls['auctionTypeId'].valid && propertyForm.controls['auctionTypeId'].touched">
              Izsoles tipam jābūt izvēlētam
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col-5">
        <div class="form-group">
          <label for="parishId">Pagasts</label>
          <ng-container *ngIf="!auctionTypeIsLoading else loading">
            <select class="form-control" id="parishId" name="parishId" formControlName="parishId"
              [class.is-invalid]="!propertyForm.controls['parishId'].valid && propertyForm.controls['parishId'].touched">
              <option [ngValue]="">-</option>
              <option *ngFor="let parish of parishes" [ngValue]="parish.id">{{parish.name}}</option>
            </select>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="regionId">Reģions</label>
              <ng-container *ngIf="!regionsIsLoading else loading">
                <select class="form-control" id="regionId" name="regionId" formControlName="regionId"
                  [class.is-invalid]="!propertyForm.controls['regionId'].valid && propertyForm.controls['regionId'].touched">
                  <option *ngFor="let reg of regions" [ngValue]="reg.id">{{reg.name}}</option>
                </select>
                <span class="text-danger"
                  *ngIf="!propertyForm.controls['regionId'].valid && propertyForm.controls['regionId'].touched">
                  Reģiona jābūt izvēlētam
                </span>
              </ng-container>
            </div>
          </div>
          <!-- <div class="col-1 pl-0">
            <button type="button" class="btn-border-less" (click)="openNewRegionModal()">
              <i class="fa fa-plus-circle fa-2" aria-hidden="true"></i>
            </button>
          </div> -->
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="countyId">Novads</label>
              <ng-container *ngIf="!countiesIsLoading else loading">
                <select class="form-control" id="countyId" name="countyId" formControlName="countyId"
                  [class.is-invalid]="!propertyForm.controls['countyId'].valid && propertyForm.controls['countyId'].touched">
                  <option *ngFor="let c of counties" [ngValue]="c.id">{{c.name}}</option>
                </select>
                <span class="text-danger"
                  *ngIf="!propertyForm.controls['countyId'].valid && propertyForm.controls['countyId'].touched">
                  Novadam jābūt izvēlētam
                </span>
              </ng-container>
            </div>
          </div>
          <!-- <div class="col-1 pl-0">
            <button type="button" class="btn-border-less" (click)="openNewCountyModal()">
              <i class="fa fa-plus-circle fa-2" aria-hidden="true"></i>
            </button>
          </div> -->
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="propertyName">Īpašuma nosaukums/adrese</label>
              <input type="text" class="form-control" name="propertyName" formControlName="propertyName"
                [class.is-invalid]="!propertyForm.controls['propertyName'].valid && propertyForm.controls['propertyName'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['propertyName'].valid && propertyForm.controls['propertyName'].touched">
                Laukam jābūt norādītam jābūt norādītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="kadestsNumber">Kadastra nr</label>
              <input type="text" class="form-control" name="kadestsNumber" formControlName="kadestsNumber"
                [class.is-invalid]="!propertyForm.controls['kadestsNumber'].valid && propertyForm.controls['kadestsNumber'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['kadestsNumber'].valid && propertyForm.controls['kadestsNumber'].touched">
                Laukam jābūt norādītam jābūt norādītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="propertyForm.controls['auctionTypeId'].value === auctionTypeEnum.felling">
          <div class="col-11">
            <div class="form-group">
              <label for="regionId">Cirtes izpildes veidi</label>
              <ng-container *ngIf="!PropertyExecutionTypeIsLoading else loading">
                <select class="form-control" id="propertyExecutionTypeId" name="propertyExecutionTypeId"
                  formControlName="propertyExecutionTypeId"
                  [class.is-invalid]="!propertyForm.controls['propertyExecutionTypeId'].valid && propertyForm.controls['propertyExecutionTypeId'].touched">
                  <option *ngFor="let c of propertyExecutionTypes" [ngValue]="c.id">{{c.name}}</option>
                </select>
                <span class="text-danger"
                  *ngIf="!propertyForm.controls['propertyExecutionTypeId'].valid && propertyForm.controls['propertyExecutionTypeId'].touched">
                  Cirtes izpildes veidam jābūt izvēlētam
                </span>
              </ng-container>
            </div>
          </div>
          <!-- <div class="col-1 pl-0">
            <button type="button" class="btn-border-less" (click)="openNewPropertyExecTypeModal()">
              <i class="fa fa-plus-circle fa-2" aria-hidden="true"></i>
            </button>
          </div> -->
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="propertyArea">Īpašuma kopplatība</label>
              <input type="text" class="form-control" name="propertyArea" formControlName="propertyArea"
                [class.is-invalid]="!propertyForm.controls['propertyArea'].valid && propertyForm.controls['propertyArea'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['propertyArea'].valid && propertyForm.controls['propertyArea'].touched">
                Īpašuma kopplatība jābūt aizpildītai!
              </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="propertyForm.controls['auctionTypeId'].value === auctionTypeEnum.property">
          <div class="col-11">
            <div class="form-group">
              <label for="forestArea">Meža kopplatība</label>
              <input type="text" class="form-control" name="forestArea" formControlName="forestArea"
                [class.is-invalid]="!propertyForm.controls['forestArea'].valid && propertyForm.controls['forestArea'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['forestArea'].valid && propertyForm.controls['forestArea'].touched">
                Cirsmas kopplatībai jābūt aizpildītai!
              </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="propertyForm.controls['auctionTypeId'].value === auctionTypeEnum.property">
          <div class="col-11">
            <div class="form-group">
              <label for="forestState">Meža stāvoklis (Izstrādāts, daļēji izstrādāts), var rakstīt brīvā formā</label>
              <input type="text" class="form-control" name="forestState" formControlName="forestState"
                [class.is-invalid]="!propertyForm.controls['forestState'].valid && propertyForm.controls['forestState'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['forestState'].valid && propertyForm.controls['forestState'].touched">
                Cirsmas stāvoklim jābūt aizpildītam!
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="youtubeVideoUrl">Youtube</label>
              <input type="text" class="form-control" name="youtubeVideoUrl" formControlName="youtubeVideoUrl"
                [class.is-invalid]="!propertyForm.controls['virtualTourUrl'].valid && propertyForm.controls['youtubeVideoUrl'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['youtubeVideoUrl'].valid && propertyForm.controls['youtubeVideoUrl'].touched">
                Youtube aizpildītam
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="virtualTourUrl">Virtuālā tūre URL</label>
              <input type="text" class="form-control" name="virtualTourUrl" formControlName="virtualTourUrl"
                [class.is-invalid]="!propertyForm.controls['virtualTourUrl'].valid && propertyForm.controls['virtualTourUrl'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['virtualTourUrl'].valid && propertyForm.controls['virtualTourUrl'].touched">
                Virtuālā tūre URL jābūt aizpildītam.
              </span>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="propertyConditionMark">īpašuma stāvokļa apzīmējums <span class="text-danger">jābūt no 1 - 10</span></label>
              <input type="text" class="form-control" name="propertyConditionMark" formControlName="propertyConditionMark"
                [class.is-invalid]="!propertyForm.controls['propertyConditionMark'].valid && propertyForm.controls['propertyConditionMark'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['propertyConditionMark'].valid && propertyForm.controls['propertyConditionMark'].touched">
                cirsmas stāvok jābūt aizpildītai!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="distanceToAGK">Attālums lidz AGK vai arī attālums līdz ceļam</label>
              <input type="text" class="form-control" name="distanceToAGK" formControlName="distanceToAGK"
                [class.is-invalid]="!propertyForm.controls['distanceToAGK'].valid && propertyForm.controls['distanceToAGK'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['distanceToAGK'].valid && propertyForm.controls['distanceToAGK'].touched">
                Attālumam jābūt norādītam jābūt norādītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="startingPrice">Izsoles sākumcena:</label>
              <input type="number" class="form-control" name="startingPrice" formControlName="startingPrice"
                [class.is-invalid]="!propertyForm.controls['startingPrice'].valid && propertyForm.controls['startingPrice'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['startingPrice'].valid && propertyForm.controls['startingPrice'].touched">
                Izsoles sākumcenai jābūt norādītai!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="minimalBiddingStep">Izsoles minimālais solis</label>
              <input type="number" class="form-control" name="minimalBiddingStep" formControlName="minimalBiddingStep"
                [class.is-invalid]="!propertyForm.controls['minimalBiddingStep'].valid && propertyForm.controls['minimalBiddingStep'].touched" readonly>
              <span class="text-danger"
                *ngIf="!propertyForm.controls['minimalBiddingStep'].valid && propertyForm.controls['minimalBiddingStep'].touched">
                Minimālajam solim jābūt norādītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="maximalBiddingStep">Izsoles maksimālais solis:</label>
              <input type="number" class="form-control" name="maximalBiddingStep" formControlName="maximalBiddingStep"
                [class.is-invalid]="!propertyForm.controls['maximalBiddingStep'].valid && propertyForm.controls['maximalBiddingStep'].touched" readonly >
              <span class="text-danger"
                *ngIf="!propertyForm.controls['maximalBiddingStep'].valid && propertyForm.controls['maximalBiddingStep'].touched">
                Minimālajam solim jābūt norādītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="duePayDateTime">Apmaksas termiņš:</label>
              <input type="date" class="form-control" name="duePayDateTime" formControlName="duePayDateTime"
                [class.is-invalid]="!propertyForm.controls['duePayDateTime'].valid && propertyForm.controls['duePayDateTime'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['duePayDateTime'].valid && propertyForm.controls['duePayDateTime'].touched">
                Minimālajam solim jābūt norādītam!
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="auctionEnds">Izsole beidzas:</label>
              <input type="datetime-local" class="form-control" name="auctionEnds" formControlName="auctionEnds"
                [class.is-invalid]="!propertyForm.controls['auctionEnds'].valid && propertyForm.controls['auctionEnds'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['auctionEnds'].valid && propertyForm.controls['auctionEnds'].touched">
                datumam jābūt norādītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-group">
              <label for="startDate">Izsoles sākuma datums: <span class="text-danger">Pārbaudi, lai ir pareizs</span></label>
              <input type="datetime-local" class="form-control" name="startDate" formControlName="startDate"
                [class.is-invalid]="!propertyForm.controls['startDate'].valid && propertyForm.controls['startDate'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['startDate'].valid && propertyForm.controls['startDate'].touched">
                Izsoles sākuma datumam jābūt norādītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="propertyForm.controls['auctionTypeId'].value === auctionTypeEnum.felling">
          <div class="col-11">
            <div class="form-group">
              <label for="allowedToWorkTillDateTime">Atļauts strādāt līdz:</label>
              <input type="date" class="form-control" name="allowedToWorkTillDateTime"
                formControlName="allowedToWorkTillDateTime"
                [class.is-invalid]="!propertyForm.controls['allowedToWorkTillDateTime'].valid && propertyForm.controls['allowedToWorkTillDateTime'].touched">
              <span class="text-danger"
                *ngIf="!propertyForm.controls['allowedToWorkTillDateTime'].valid && propertyForm.controls['allowedToWorkTillDateTime'].touched">
                Lielumam jābūt aizpildītam!
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div class="form-check">
              <input class="form-check-input" formControlName="isEnabled" type="checkbox" value="" id="flexCheckChecked"
                checked>
              <label class="form-check-label" for="flexCheckChecked">
                Atļauts publicēt lietotājiem
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <button class="btn btn-primary">Saglabāt</button>
          </div>

          <div class="col-11 mt-3">
            <button type="button" (click)="sendNotifiations()" [disabled]="sendingNotifications" class="btn btn-primary">Paziņot par izsoli visiem lietotājiem!</button>
          </div>
        </div>
      </div>
      <div class="col-6">
        <app-file-upload></app-file-upload>
        <br>
        <br>
        <h5>Kopējā krāja - var obligāti nepildīt, nebūs aizpildīts, tad nekas nerādīsies klientiem</h5>
        <div formArrayName="propertyTreeQuantities">

          <ng-container *ngFor="let treeGroup of treeTypes.controls; let i = index">
            <div class="row" [formGroup]="treeGroup">
              <div class="col-5">
                <div class="form-group">
                  <label for="quantity">Daudzums</label>
                  <input type="number" class="form-control" name="quantity" formControlName="quantity">
                </div>
              </div>
              <div class="col-5">
                <div class="form-group">
                  <label for="treeType">Koksnes tips</label>
                  <select class="form-control" id="treeTypeId" name="treeTypeId" formControlName="treeTypeId">
                    <option *ngFor="let c of propertyTreeTypes" [ngValue]="c.id">{{c.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </ng-container>
          <button type="button" class="btn btn-primary" (click)="createFormGroup()">+</button>
        </div>
      </div>
    </div>
  </ng-container>
</form>

<ng-template #loading>
  <tr>
    <td>
      <h4 class="text-center">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </h4>
    </td>
  </tr>
</ng-template>