import { ContactService } from './contact.service';
import { finalize } from 'rxjs/operators';
import { AuctionService } from './../../auction/auction.service';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { PropertyWithBid } from '../../models/all.models';
import smoothscroll from 'smoothscroll-polyfill';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { ToasterService } from './../../shared/toaster/toaster.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('collapseAnimation', [
      state('collapsed', style({ height: '0', padding:0, opacity: '0', display: 'none' })),
      state('expanded', style({ height: '*', padding: 10, opacity: '1', display: 'block' })),
      transition('collapsed => expanded', animate('300ms ease-in')),
      transition('expanded => collapsed', animate('300ms ease-out'))
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit  {
  @ViewChild('videoplayer') videoPlayerRef!: ElementRef<HTMLVideoElement>;
  isLoadingProperties = true;
  newestProperties: PropertyWithBid[] = [];
  imgs = new Array();
  retryCount = 0;
  isCollapsed: boolean[] = [true, true, true, true, true, true, true];
  contactForm: UntypedFormGroup;
  contactFormCalled = false;
  contactFormIsLoading = false;
  // aboutUsImageActive = false;
  eventCounterActive = false;
  
  @ViewChild('aboutImgContainer') fadeImage: ElementRef;
  // @ViewChild('counterContainer') counterContainer: ElementRef;
  // @ViewChild('countOne') counterOne: ElementRef;
  // @ViewChild('countTwo') counterTwo: ElementRef;
  // @ViewChild('countThree') counterThree: ElementRef;
  // @ViewChild('countFour') counterFour: ElementRef;
  @ViewChildren('counter') cardElements!: QueryList<ElementRef>;
  @ViewChildren('cardElement') counterElements!: QueryList<ElementRef>;

  constructor(
    private auctionService: AuctionService,
    private contactService: ContactService,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private viewportScroller: ViewportScroller,
    private toaster: ToasterService,
  ) {
    smoothscroll.polyfill();
    const offerSeen = localStorage.getItem("OfferSeen");
    if (!offerSeen) {
      localStorage.setItem("OfferSeen", "1");
      this.router.navigate(['offer']);
    }
   }

  ngOnInit() {
    this.auctionService.getNewestAuctions(8)
    .pipe(
      finalize(() => this.isLoadingProperties = false)
    )
    .subscribe(data => this.newestProperties = data);

    this.pload(
      './../../../assets/images/logo.png'
    );

    this.createForm();
  }

  ngOnDestroy() {
    this.toaster.resetToasts();
  }

  ngAfterViewInit() {
    this.playVideo();

    const options = {
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          console.log('annimating', entry.target.className);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    this.cardElements.forEach(card => {
      observer.observe(card.nativeElement);
    });

    console.log(this.counterElements);

    this.counterElements.forEach(card => {
      observer.observe(card.nativeElement);
    });

    observer.observe(this.fadeImage.nativeElement);
  }

  navigateToSection(section: string, isMobile = false) {
    this.router.navigate(['/']);
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(section);
    }, 100);
  }
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll() {
  //   // if(!this.aboutUsImageActive) {
  //   //   this.checkImage();
  //   // }

  //   if(!this.eventCounterActive) {
  //     this.checkCounter();
  //   }
  // }

  // checkCounter() {
  //   const image = this.counterContainer.nativeElement;
  //   const rect = image.getBoundingClientRect();
  //   const inView = rect.top >= 0 && rect.top + 100 <= (window.innerHeight || document.documentElement.clientHeight);

  //   if (inView) {
  //     this.eventCounterActive = true;
  //     this.loop(this.counterOne.nativeElement, 20, '01');
  //     this.loop(this.counterTwo.nativeElement, 30, '02');
  //     this.loop(this.counterThree.nativeElement, 40, '03');
  //     this.loop(this.counterFour.nativeElement, 50, '04');
  //   }
  // }

  // loop(ref: ElementRef<any>, runCount: number, endNumber: string) {
  //   let count = 0;
  //   const intervalTime = 30; // milliseconds between each iteration
  
  //   const interval = setInterval(() => {
  //     if (count < runCount) {
  //       // Generate random number from 1 to 100
  //       const randomNumber = Math.floor(Math.random() * 100) + 1;
  
  //       // Assign random number to element's innerHTML
  //       this.renderer.setProperty(ref, 'innerHTML', randomNumber);
  
  //       count++;
  //     } else {
  //       // After 10 iterations, set the endNumber
  //       this.renderer.setProperty(ref, 'innerHTML', endNumber.toString());
  
  //       // Reset count after 10 iterations
  //       count = 0;
  
  //       // Clear the interval after completing the loop
  //       clearInterval(interval);
  //     }
  //   }, intervalTime);
  // }

  checkImage() {
    // const image = this.fadeImage.nativeElement;
    // const rect = image.getBoundingClientRect();
    // const inView = rect.top >= 0 && rect.top + 250 <= (window.innerHeight || document.documentElement.clientHeight);

    // if (inView) {
    //   this.renderer.addClass(image, 'visible');
    //   this.aboutUsImageActive = true;
    // }
  }
  

  pload(...args: any[]): void {
    for (var i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = args[i];
    }
  }

  submitForm() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      return;
    }

    this.contactFormIsLoading = true;
    this.contactService.PostContact(this.contactForm.value)
      .pipe(finalize(() => 
        {
          this.contactFormIsLoading = false;
          this.toaster.show('success', 'Paldies!', 'Jūsu pieprasījums ir veiksmīgi saņemts, drīz ar jums sazināsimies.', 5000);
        }))
      .subscribe();
  }


  playVideo() {
    const videoPlayer = this.videoPlayerRef.nativeElement;
    videoPlayer.muted = true;
    videoPlayer.play().catch((error) => {
      this.retryCount ++;

      if (this.retryCount < 10) {
        setTimeout(() => {
          this.playVideo();
        }, 200);
      }
    });
  }

  private createForm(): void {
    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormControl('', Validators.required),
      message: new UntypedFormControl('', Validators.required)
    })
  }
}
