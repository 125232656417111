<div class="container page-container mb-5">
  <h2 class="text-center">Kontakti</h2>
  <p class="text-center mb-5">Ar prieku atbildēsim uz visiem Jūsu jautājumiem</p>
  <div class="row">
    <div class="col-12 col-md-3">
      <div class="communication-block">
        <div class="contact-item">
          <div class="item-phone"></div>
          <div>
            <h6 class="text-green">TĀLRUNIS</h6>
            <a href="tel:+37128270059">+371 28 270 059</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="contact-item">
        <div class="item-address"></div>
        <div>
          <h6 class="text-green">ADRESE</h6>
          <p>Jelgavas nov., Glūdas pag., Nākotne, <br>Skolas iela 18 - 2, LV-3040</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="contact-item">
        <div class="item-mail"></div>
        <div class="">
          <h6 class="text-green">E-PASTS</h6>
          <a href="mailto:info@mezosle.lv">info@mezsole.lv</a>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-7 m-auto">
      <div class="contact-item-m-auto">
        <div class="item-docs"></div>        
        <div class="text-left">
          <h6 class="text-center">REKVIZĪTI</h6>
          <h5 class="text-green" style="text-transform: none">Mežsole, SIA</h5>
          <span class="font-weight-bold">Reģ. Nr.:</span> 40203581119 <br>
          <span class="font-weight-bold">PVN Reģ. Nr.:</span> LV40203581119<br>
          <span class="font-weight-bold">Juridiskā adrese:</span> Jelgavas nov., Glūdas pag., Nākotne, <br>Skolas iela 18 - 2, LV-3040<br>

          <span class="font-weight-bold">Citadele</span> <br>
          <span class="font-weight-bold">Konts:</span> LV72PARX0032432010001 <br>
        </div>
      </div>
    </div>
  </div>
</div>