import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-first-time-offer',
  templateUrl: './first-time-offer.component.html',
  styleUrls: ['./first-time-offer.component.scss']
})

export class FirstTimeOfferComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
