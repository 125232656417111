import { UserService } from './shared/user.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService, private userService: UserService) {
    //translate.setDefaultLang('en');
    const token = localStorage.getItem('token');
    if(token != null) {
      this.userService.getUser().subscribe();
    }
  }
  title = 'app';
}
