import { PropertyService } from './../../property/property.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileDto } from '../../../models/all.models';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageService } from '../../Image.service';

@Component({
  selector: 'app-county-edit',
  templateUrl: './county-edit.component.html',
  styleUrls: ['./county-edit.component.css']
})
export class CountyEditComponent implements OnInit {
  id;
  @Input()
  requiredFileType: string;

  fileName = '';
  uploadProgress: number;
  uploadSub: Subscription;

  countyForm: UntypedFormGroup;
  isLoading = false;
  file: File;
  constructor(
    private http: HttpClient, 
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private imageService: ImageService
    ) {
      this.createForm();
    }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params["id"];
    // this.getImage();
    this.getCounty(+this.id);
  }

  getCounty(id: number) {
    this.countyForm.controls['id'].setValue(id);
    this.propertyService.getCountyById(id)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(data => {
        this.countyForm.controls['id'].setValue(id);
        this.countyForm.controls['name'].setValue(data.name);
        this.getImage(data.thumbnail)
      });
  }

  submitForm() {
    this.isLoading = true;
    this.propertyService.updateCounty(this.countyForm.value)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe();
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    event.currentTarget.placeholder = "Type name here..";
  }

  getImage(image: string) {
    const upload$ = this.http.get("/api/File/CountyImage/"+ this.id.toString(), { responseType: "arraybuffer" });
    upload$.subscribe(data => {
      
      var arrayBufferView = new Uint8Array( data );
      var blob = new Blob( [ arrayBufferView ], { type: "image/png" } );
      var urlCreator = window.URL || (window as any).webkitURL;      
      var imageUrl = urlCreator.createObjectURL( blob );
      (<HTMLImageElement>document.getElementById("ItemPreview")).src  = imageUrl;
    })
  }

  // upload() {
  //   if (this.file) {
  //     this.fileName = this.file.name;
  //     const formData = new FormData();
  //     formData.append('countyId', this.id);
  //     formData.append('file', this.file, this.fileName);

  //     const upload$ = this.http.post("/api/File/UploadImage", formData, {
  //       reportProgress: true,
  //       observe: 'events'
  //     })
  //       .pipe(
  //         finalize(() => {
  //           this.getImage();
  //         })
  //       );

  //     this.uploadSub = upload$.subscribe(event => {
  //       if (event.type == HttpEventType.UploadProgress) {
  //         this.uploadProgress = Math.round(100 * (event.loaded / event.total));
  //       }
  //     })
  //   }
  // }

  upload() {
    if (this.file) {
      this.imageService.uploadImage(this.file, this.countyForm.controls["id"].value).subscribe(
        (response) => {
          if(response.body?.uniqueFileName) {
            this.getImage(response.body.uniqueFileName);
          }
        },
        (error) => {
          console.error('Image upload failed:', error);
          // Handle errors here, e.g., display an error message.
        }
      );
    }
  }

  private createForm(): void {
    this.countyForm = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
    })
  }
}
