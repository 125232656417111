import { PropertyService } from './../../property/property.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileDto } from '../../../models/all.models';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageService } from '../../Image.service';

@Component({
  selector: 'app-county-edit',
  templateUrl: './region-edit.component.html',
  styleUrls: ['./region-edit.component.css']
})
export class RegionEditComponent implements OnInit {
  id;
  @Input()
  requiredFileType: string;

  fileName = '';
  uploadProgress: number;
  uploadSub: Subscription;

  regionForm: UntypedFormGroup;
  isLoading = false;
  selectedFile: File | null = null;
  thumbnailUrl: string = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private imageService: ImageService,
    @Inject('BASE_URL') private baseUrl: string
    ) {
      this.createForm();
    }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params["id"];
    this.getCounty(+this.id);
  }
  
  getCounty(id: number) {
    this.regionForm.controls['id'].setValue(id);
    this.propertyService.getRegionById(id)
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(data => {
      this.regionForm.controls['id'].setValue(id);
      this.regionForm.controls['name'].setValue(data.name);
      this.getImage(data.thumbnail);
      });
  }

  submitForm() {
    this.isLoading = true;
    this.propertyService.updateCounty(this.regionForm.value)
        .pipe(finalize(() => {
          this.isLoading = false;
        }))
        .subscribe();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }


  getImage(uniqueFileName: string) {
    // const upload$ = this.http.get("/api/File/" + uniqueFileName, { responseType: "arraybuffer" });
    this.thumbnailUrl = this.baseUrl +'File/' + uniqueFileName; 
    // upload$.subscribe(data => {
      
    //   var arrayBufferView = new Uint8Array( data );
    //   var blob = new Blob( [ arrayBufferView ], { type: "image/png" } );
    //   var urlCreator = window.URL || (window as any).webkitURL;      
    //   var imageUrl = urlCreator.createObjectURL( blob );
    //   (<HTMLImageElement>document.getElementById("ItemPreview")).src  = imageUrl;
    // })
  }



  private createForm(): void {
    this.regionForm = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
    })
  }
}
