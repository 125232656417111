import { PropertyService } from './../property.service';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Property } from '../../../models/all.models';

@Component({
  selector: 'app-view-properties',
  templateUrl: './view-properties.component.html',
  styleUrls: ['./view-properties.component.css']
})
export class ViewPropertiesComponent implements OnInit {
  properties: Property[] = [];
  isLoading = false;

  constructor(private propertyService: PropertyService) { }

  ngOnInit() {
    this.isLoading = true;
    this.propertyService.getAllProperties()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe( x => this.properties = x )
  }
}
