import { Component, OnInit } from '@angular/core';
import { AdminService } from './admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  contractCount = 0;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.getAllSignableContracts()
      .subscribe(data => {
        this.contractCount = data.length;
      });      
  }
}
