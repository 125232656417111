<form [formGroup]="articleForm" (ngSubmit)="submitForm()">

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="publicationDate">Publicēšanas datums</label>
        <input type="datetime-local" class="form-control" name="publicationDate" formControlName="publicationDate"
          [class.is-invalid]="!articleForm.controls['publicationDate'].valid && articleForm.controls['publicationDate'].touched">
        <span class="text-danger"
          *ngIf="!articleForm.controls['publicationDate'].valid && articleForm.controls['publicationDate'].touched">
          norādi publicēšanas datumu!
        </span>
      </div>
    </div>
    <div class="col-12">
      <input type="file" #fileInput accept=".png" (change)="onFileSelected($event)" />
    </div>
    <div class="col-12">
      <input type="hidden" class="form-control" name="thumbnail" formControlName="thumbnail"
        [class.is-invalid]="!articleForm.controls['thumbnail'].valid && articleForm.controls['thumbnail'].touched">
      <span class="text-danger"
        *ngIf="!articleForm.controls['thumbnail'].valid && articleForm.controls['thumbnail'].touched">
        thumbnail jābūt aizpildītam!
      </span>
      <div *ngIf="base64String">
        <img [src]="base64String" style="width: 200px;" alt="Base64 Image" />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="title">Virsraksts</label>
        <input type="text" class="form-control" name="title" formControlName="title"
          [class.is-invalid]="!articleForm.controls['title'].valid && articleForm.controls['title'].touched">
        <span class="text-danger"
          *ngIf="!articleForm.controls['title'].valid && articleForm.controls['title'].touched">
          Jābūt aizpildītam!
        </span>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="shortDescription">Īsais apraksts ( baits uz lasīt vairāk)</label>
        <textarea rows="4" class="form-control" name="shortDescription" formControlName="shortDescription"
          [class.is-invalid]="!articleForm.controls['shortDescription'].valid && articleForm.controls['shortDescription'].touched">
          </textarea>
        <span class="text-danger"
          *ngIf="!articleForm.controls['shortDescription'].valid && articleForm.controls['shortDescription'].touched">
          Jābūt aizpildītam!
        </span>
      </div>
    </div>
  </div>
  <div class="container-ion">
    <ion-content class="ion-padding">
      <quill-editor formControlName="content"></quill-editor>
    </ion-content>
  </div>

  <button type="submit" class="btn btn-primary mt-3">Save</button>
</form>

