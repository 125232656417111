import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-loading',
  templateUrl: './small-loading.component.html',
  styleUrls: ['./small-loading.component.scss']
})
export class SmallLoadingComponent implements OnInit {

  @Input() center = false;
  constructor() { }

  ngOnInit() {
  }

}
