import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit,  OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PropertyWithBid } from '../../models/all.models';
import { Inject } from '@angular/core';
import { AuctionService } from '../auction.service';
import { Subscription, finalize, interval, startWith, switchMap } from 'rxjs';
import { ToasterService } from '../../shared/toaster/toaster.service';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-single-auction',
  templateUrl: './single-auction.component.html',
  styleUrls: ['./single-auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleAuctionComponent implements OnInit, OnDestroy {
  @Input() property: PropertyWithBid;
  ended = false;
  private subscriptionPrice: Subscription = Subscription.EMPTY;
  thumbnailUrl: string;
  auctionEndDate: Date;
  maxBid = 0;
  bidCountWording = "";
  subscriptionLoading = false;
  status = '';
  totalTrees = 0;
  constructor(private http: HttpClient,
    private auctionService: AuctionService,
    private router: Router,
    private toaster: ToasterService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    @Inject('BASE_URL') private baseUrl: string,
  ) { }

  ngOnInit() {
    // this.thumbnailUrl = 'https://www.latvia.travel/sites/default/files/styles/mobile_promo/public/media_image/Kurzeme_0.jpg?itok=HmJu8zFo';
     this.thumbnailUrl =  'api/File/'+ this.property.county.thumbnail;//
    this.ended = new Date(this.property.auctionEnds) < new Date();
    this.auctionEndDate = this.property.auctionEnds;
    this.setUpMaxBidInfo();
    if (!this.ended) {
      this.createSubsription();
    }
    this.setBidCountWording(this.property.auctionBids?.length);

    if(this.property?.propertyTreeQuantities)
    {
      this.property.propertyTreeQuantities.forEach(x => {
        this.totalTrees += x.quantity
      })
    }

    // this.bidCountWording

    
    if (new Date(this.property.auctionEnds) < new Date()) {
      this.status = 'ended';
    }
    else if (new Date(this.property.startDate) > new Date()) {
      this.status = 'scheduled';
    }
    else {
      this.status = 'active';
    }
    console.log(this.status);
  }

  ngOnDestroy(): void {
    this.subscriptionPrice.unsubscribe();
    this.toaster.resetToasts();
  }

  setUpMaxBidInfo() {
    if (this.property.auctionBids?.length) {
      const valuesArray = this.property.auctionBids.map(obj => obj.price);
      this.maxBid = Math.max(...valuesArray);
    } else {
      this.maxBid = this.property.startingPrice;
    }
  }

  navigate(event: Event) {
    if((event.target as HTMLElement).classList.contains("icon-heart")) {
      return;
    }
    this.router.navigate(['/auction', this.property.id]);
  }

  private setBidCountWording(count: number | null) {
    if (!count) {
      this.bidCountWording = 'Nav solījumu';
    } else {
      this.bidCountWording = count === 1 ? '1 solījums' : count + ' solījumi';
    }
  }

  subscribeToAuction(id: number) {

    if(!this.userService.applicationUser) {
      this.toaster.show('warning', 'Lūdzu pieslēdzies sistēmai!', 'Lai izsoli pievienotu favorītiem nepieciešams pieslēgties sistēmai. Vēlāk izsoļu priekšmetus varēsi aplūkot sadaļā "Kabinets".', 500);
      return;
    }

    this.subscriptionLoading = true;
    if (this.property.isSubscribed) {
      this.auctionService.unsubscribeToAuction(id)
        .pipe(finalize(() => {
          this.subscriptionLoading = false;
          this.cdr.markForCheck();
        }))
        .subscribe(() => {
          this.property.isSubscribed = false;
          this.toaster.show('success', 'Lieliski!', 'Izsole veiksmīgi noņemta no favorītiem', 500);
        });
    } else {
      this.auctionService.subscribeToAuction(id)
      .pipe(finalize(() => {
        this.subscriptionLoading = false;
        this.cdr.markForCheck();
      }))
      .subscribe(() => {
        this.property.isSubscribed = true;
        this.toaster.show('success', 'Lieliski!', 'Izsole veiksmīgi pievienota favorītiem. Tagad vari to apskatīt sadaļā "Kabinets".', 500);
      });
    }
  }

  private createSubsription() {
    this.subscriptionPrice = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.auctionService.getActualPrice(this.property.id)),
      ).subscribe(data => {
        if (data) {
          this.maxBid = data.price;
          this.auctionEndDate = data.auctionEndDate;
          this.setBidCountWording(data.bidHistory?.length);
          this.cdr.markForCheck();
        }
      });
  }
}
