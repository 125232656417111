<div class="container-fluid">
  <div class="row"> 
    <div class="col-3 pt-3 nav-container">
      <h3>Navigācija</h3>
      <ul class="nav flex-column">
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['properties']">Izsoļu īpašumi</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['users']">Lietotāju tiesības</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['county']">Novadi</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['regions']">Reģioni</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['tree-types']">Kokses tipi</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['translations']">Tulkojumi</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['signable-contracts']">Parakstāmie Līgumi
            <span class="notification-badge" *ngIf="contractCount > 0">{{contractCount}}</span>
          </a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link" [routerLink]="['post']">Aktualitātes</a>
        </li>
      </ul>
    </div>
    <div class="col-9 py-3 px-5">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>