import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { CabinetInfoComponent } from './cabinet-info/cabinet-info.component';
import { ContractUploadComponent } from './ContractUpload/ContractUpload.component';
import { AddPropertyComponent } from './../admin/property/add-property/add-property.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CabinetComponent } from './cabinet.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthGuard } from '../auth/auth.guard';
import { HowToPayForDepositComponent } from './how-to-pay-for-deposit/how-to-pay-for-deposit.component';

@NgModule({
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot([
            { path: 'cabinet', component: CabinetComponent, canActivate: [AuthGuard],
                children: [
                    { path: '', component: CabinetInfoComponent },
                    { path: 'confirm-email', component: ConfirmEmailComponent },
                    { path: 'contract', component: ContractUploadComponent },
                    { path: 'deposit-help', component: HowToPayForDepositComponent },
                ]
            },
        ]),
    ],
    declarations: [CabinetComponent, CabinetInfoComponent, ConfirmEmailComponent, ContractUploadComponent, HowToPayForDepositComponent],
    exports: [RouterModule],
    providers: []
})
export class CabinetModule { }
