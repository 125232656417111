<ng-container *ngIf="form">
  <form [formGroup]="form">
    <div class="row mt-3">
      <div class="col-12">
        <h2>Pārvaldīt lietotāja piekļuvi</h2>
        <ng-container *ngIf="!isLoading; else loading">
          <p>Uzņēmums: {{userWithRoles.user.companyName}}</p>
          <p>Reģistrācijas nr: {{userWithRoles.user.companyRegistrationNumber}}</p>
          <p>Ēpasts: {{userWithRoles.user.email}}</p>
          <p>Vārds: {{userWithRoles.user.firstName}}</p>
          <p>Uzvārds: {{userWithRoles.user.surname}}</p>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Lomas nosaukums</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody formArrayName="roles">
                <tr *ngFor="let role of getControls(); let i = index">
                  <td>{{role.get('name').value}}</td>
                  <td>
                    <div [formGroupName]="i">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input id="selected" formControlName="selected" type="checkbox">
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
          <button type="button" class="btn btn-primary" (click)="saveRoles()">Saglabāt</button>
        </ng-container>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>