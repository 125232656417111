import { TreeType } from './../../../models/all.models';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PropertyService } from '../../property/property.service';
import { finalize } from 'rxjs/operators';
import { NewRegionModalComponent } from '../../region-modals/new-region-modal/new-region-modal.component';

@Component({
  selector: 'app-tree-trype',
  templateUrl: './tree-trype.component.html',
  styleUrls: ['./tree-trype.component.css']
})
export class TreeTrypeComponent implements OnInit {
  treeTypes: TreeType[] = [];
  isLoading = false;

  constructor(
    private modalService: BsModalService,
    private propertyService: PropertyService) { }

  modalRef?: BsModalRef;
  ngOnInit() {
    this.getTreeTypes();
  }

  getTreeTypes() {
    this.isLoading = true;
    this.propertyService.getAllTreeTrypes()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe( x => this.treeTypes = x)
  }

  openNewRegionModal(): void {
    this.modalRef = this.modalService.show(NewRegionModalComponent,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getTreeTypes();
    });
  }

}
