<div class="container page-container">
  <div class="row">
    <div class="col-12 mb-3 filter-container">
      <div class="col-10 col-sm-12 mx-auto p-1">
        <div id="auction_filter" class="d-flex flex-column flex-md-row align-items-md-center mx-auto p-2">
          <div class="text-nowrap mr-2 mb-1 mb-sm-0">
            <img class="filter-image" src="./../../assets/images/icon-image/filter.svg">
          </div>
          <div class="d-block d-md-flex align-items-center mx-auto" accept-charset="UTF-8">
            <div class="d-md-flex d-block justify-content-between">
              <div class="form-group mb-0 d-block d-md-flex">
                <label for="regionId" class="col-sm-4 col-12 col-form-label">REĢIONS</label>
                <div class="col-sm-9 col-12 ">
                  <ng-container *ngIf="!regionsIsLoading else loadingProperties">
                    <select class="form-control region-selection" id="regionId" name="regionId" [(ngModel)]="auctionFilter.regionId"
                      (ngModelChange)="onChange()">
                      <option [ngValue]="null">Visi</option>
                      <option *ngFor="let reg of regions" [ngValue]="reg.id">{{reg.name}}</option>
                    </select>
                  </ng-container>
                </div>
              </div>
              <div class="mx-2 mx-md-3 d-none d-sm-block top-pad">|</div>
              <div class="d-block d-sm-flex align-items-center">
                <div class="form-check form-check-inline d-block d-sm-inline ">
                  <div class="custom-control custom-checkbox">
                    <input [(ngModel)]="auctionFilter.isActive" (ngModelChange)="onChange()"
                      class="custom-control-input" type="checkbox" id="estate_status_active" name="estate_status_active"
                      value="1">
                    <label class="custom-control-label form-label menu-title" for="estate_status_active">
                      AKTĪVĀS </label>
                  </div>
                </div>
                <div class="form-check form-check-inline mr-0 d-block d-sm-inline">
                  <div class="custom-control custom-checkbox">
                    <input [(ngModel)]="auctionFilter.isEnded" (ngModelChange)="onChange()" class="custom-control-input"
                      type="checkbox" id="estate_status_ended" name="estate_status_ended" value="1">
                    <label class="custom-control-label form-label menu-title" for="estate_status_ended">
                      BEIGUŠĀS </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isLoadingProperties; else loadingProperties" class="mb-4">
      <ng-container *ngIf="newestProperties?.length">
        <ng-container *ngFor="let auct of newestProperties; let index = index">
          <ng-container *ngIf="index < currentTake">
            <div class="col-10 col-sm-12 col-lg-3 col-md-4 mb-4 mx-auto">
              <app-single-auction [property]="auct"></app-single-auction>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!newestProperties?.length">
        <div class="col-12">
          <h5>Pēc jūsu meklēšanas kritērijiem netika atrasta neviena izsole!</h5>
        </div>
      </ng-container>
      <div class="container">

        <div class="row">
          <div class="col-12">
            <div class="footer-container">
              <!-- <img class="logo" src="./../../../assets/images/logo/Mezsole_logo_yellow.svg"> -->
              <div class="title-container">
                <h2 class="title">Atklājiet iespējas un potenciālu, kas slēpjas meža īpašumu tirgū</h2>
                <p class="mb-0">Iepazīsties ar detalizētāku informāciju par darījuma procesu un nepieciešajamjiem dokumentiem</p>
                <a [routerLink]="['../how-we-work']" class="btn btn-read-more btn-white">Lasīt vairāk</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="newestProperties?.length">        
        <ng-container *ngFor="let auct of newestProperties; let index = index">
          <ng-container *ngIf="index >= currentTake">
            <div class="col-10 col-sm-12 col-lg-3 col-md-4 mb-4 mx-auto" >
              <app-single-auction [property]="auct"></app-single-auction>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="hasMore">
        <div class="col-12 text-center mt-3 mb-4">
          <a class="cursor-pointer" (click)="loadMore()">Ielādēt vairāk</a>
        </div>
      </ng-container>
    </ng-container>

    <button type="button" id="openButton" class="fixed-button btn btn-primary filter-button" (click)="toggleFilterOption(true)">
      <div class="text-nowrap mr-2 mb-1 mb-sm-0">
        <i class="icon-icon-filter pr-1" ></i>
        <span class="menu-title">Filtrēt</span>
      </div>
    </button>
    <div #floatingContainer class="floating-container">
      <button type="button" class="close btn-close pt-2 pr-2 pull-right" aria-label="Close" (click)="toggleFilterOption(false)">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <div class="container-content">
        <div id="auction_filter" class="">
          <div class="text-nowrap mr-2 mb-1 mb-sm-0">
            <i class="icon-icon-filter"></i>
            <span class="menu-title">FILTRS</span>
          </div>
          <div class="d" accept-charset="UTF-8">
            <div class="">
              <div class="form-group">
                <label for="regionId" class="col-12 p-0 pt-3">Novadi</label>
                <div class="col-12 p-0">
                  <ng-container *ngIf="!regionsIsLoading else loadingProperties">
                    <select class="form-control" id="regionId" name="regionId" [(ngModel)]="auctionFilter.regionId"
                      (ngModelChange)="onChange()">
                      <option [ngValue]="null">Visi</option>
                      <option *ngFor="let reg of regions" [ngValue]="reg.id">{{reg.name}}</option>
                    </select>
                  </ng-container>
                </div>
              </div>
              <label for="regionId" class="col-12 p-0 pt-3">Izsoles status</label>
              <div class="d-flex align-items-center">
                <div class="form-check form-check-inline d-block d-sm-inline">
                  <div class="custom-control custom-checkbox">
                    <input [(ngModel)]="auctionFilter.isActive" (ngModelChange)="onChange()"
                      class="custom-control-input" type="checkbox" id="estate_status_active" name="estate_status_active"
                      value="1">
                    <label class="custom-control-label form-label menu-title" for="estate_status_active">
                      AKTĪVĀS </label>
                  </div>
                </div>
                <div class="form-check form-check-inline mr-0 d-block d-sm-inline">
                  <div class="custom-control custom-checkbox">
                    <input [(ngModel)]="auctionFilter.isEnded" (ngModelChange)="onChange()" class="custom-control-input"
                      type="checkbox" id="estate_status_ended" name="estate_status_ended" value="1">
                    <label class="custom-control-label form-label menu-title" for="estate_status_ended">
                      BEIGUŠĀS </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingProperties>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>


<app-toaster-container></app-toaster-container>

