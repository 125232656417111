import { NewRegionModalComponent } from './../new-region-modal.component';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { Region } from '../../../administration.models';
import { PropertyService } from '../../../property/property.service';

@Component({
  selector: 'app-Regions',
  templateUrl: './Regions.component.html',
  styleUrls: ['./Regions.component.css']
})
export class RegionsComponent implements OnInit {

  regions: Region[] = [];
  isLoading = false;

  constructor(
    private modalService: BsModalService,
    private propertyService: PropertyService) { }

  modalRef?: BsModalRef;
  ngOnInit() {
    this.getRegions();
  }

  getRegions() {
    this.isLoading = true;
    this.propertyService.getAllRegions()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe( x => this.regions = x )
  }

  openNewRegionModal(): void {
    this.modalRef = this.modalService.show(NewRegionModalComponent,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getRegions();
    });
  }

}
