import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, pipe } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-ContractUpload',
  templateUrl: './ContractUpload.component.html',
  styleUrls: ['./ContractUpload.component.scss']
})
export class ContractUploadComponent implements OnInit, OnDestroy {
  uploadValid = true;
  fileName = '';
  uploadProgress: number;
  uploadSub: Subscription = Subscription.EMPTY;
  error = false;
  countyForm: UntypedFormGroup;
  isLoading = false;
  file: File;
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.uploadSub.unsubscribe();
  }

  upload() {
    if (this.file) {
      this.isLoading = true;
      this.fileName = this.file.name;
      const formData = new FormData();
      formData.append('fileFormat', this.file.type);
      formData.append('file', this.file, this.fileName);

      const upload$ = this.http.post("/api/Contract/UploadContract", formData, {
        reportProgress: true,
        observe: 'events'
      })
        .pipe(
          finalize(() => {
            setTimeout(() => this.router.navigate(['cabinet']), 5000);
          })
        );

      this.uploadSub = upload$.pipe(finalize(()=> this.isLoading = false))
        .subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {        
            this.uploadProgress = Math.round(100 * (event.loaded / event.total));
          }
        },
        err => this.error = true)
    } else {
      this.uploadValid = false;
    }
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    event.currentTarget.placeholder = "Type name here..";
    this.uploadValid = true;
  }
}
