import { UserService } from './../shared/user.service';
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService) {
        
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // Checking if token is present if not redirecting to login page
        if(localStorage.getItem('token') != null) {
            // Getting Permitted roles to access current route from route modules
            const roles = next.data['permittedRoles'] as Array<string>;
            // If there are required roles to current route
            if (roles) {
                // Checking if user has roles to access current route
                if (this.userService.userHasRoles(roles))  {
                    return true;
                } else {
                    this.router.navigate(['/how-did-you-get-here']);
                    return false;
                    // return true;
                }
            } else {
                // Current route has no roles
                return true;
            }
        }
        else {
            this.router.navigate(['/unauthorized']);
            return false;
        }
    }
}