<div class="container pt-5 mb-5">
  <div class="row">
    <div class="col-12">
      <h4>
        Ēpasta adreses apstiprināšana
      </h4>
      <hr>
      <div class="row">
        <div class="col-12 mt-3">
          <h5 class="text-left">Lai turpinātu nepieciešams apstiprināt e-pasta adresi.</h5>
          <p class="text-left mb-0">Uz jūsu norādīto e-pasta adresi "{{userEmail}} tika nosūtīts e-pasts, ar
            verifikācijas kodu.
            Iekopejiet drošības kodu zemāk dotajā laukā, un spiediet "Apstiprināt".</p>
          <br>
        </div>
      </div>
      <ng-container *ngIf="!emailValdiationCallResult.success">
        <form [formGroup]="emailConfirmationForm" id="msform" (ngSubmit)="validateEmail()">
          <div class="row">
            <div class="col-12 col-md-8">
              <input type="text" class="form-control" name="emailVerificationCode"
                formControlName="emailVerificationCode"
                [class.is-invalid]="!emailConfirmationForm.controls['emailVerificationCode'].valid && emailConfirmationForm.controls['emailVerificationCode'].touched" />

              <span class="text-danger text-left"
                *ngIf="emailValdiationCallResult.called && !emailValdiationCallResult.success">
                e-pasta apstiprinājuma kods neizdevās, lūdzu pārbaudiet ievadīto kodu un mēģiniet vēlreiz.
              </span>
            </div>
            <div class="col-12 col-md-4">
              <button type="submit" class="w-100 btn btn-primary"
                *ngIf="!emailValidationLoading; else loading">Apstiprināt</button>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-8 text-left pt-5">
            <p><strong>Nesaņēmi e-pastu? </strong> <a role="button" *ngIf="!emailResendLoading; else smallLoading"
                (click)="resendEmail()">Nosūtīt atkārtoti!</a>
              <span *ngIf="emailResendSuccessful.called">
                <span class="text-danger" *ngIf="!emailResendSuccessful.success"> Kaut kas nogāja greizi.</span>
                <span class="text-success" *ngIf="emailResendSuccessful.success"> Veiksmīgi nosūtīts</span>
              </span>
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="emailValdiationCallResult.success">
        <span class="text-success text-left"
                *ngIf="emailValdiationCallResult.called && emailValdiationCallResult.success">
                E-pasta adrese veiksmīgi apstiprināta. Jūs tiekas maršrutēts uz līguma parakstīšanas skatu.
              </span>
              <ng-container *ngTemplateOutlet="loading"></ng-container>
      </ng-container>
      
      <div class="row">
        <div class="col-12 mt-3">
          <h5 class="text-left">Kāpēc mums nepieciešams tava e-pasta apstiprinājums?</h5>
          <p class="text-left mb-0">Pēc ēpasta apstiprināšanas, uz jūsu norādīto e-pasta adresi tiks nosūtīts sadarbības
            līgums,
            kas satur konfidenciālu informāciju. Apstiprinot savu e-pasta adresi mēs varam pārliecināties, ka:
          </p>
          <ol>
            <li>Jūsu ievadītā e-pasta adrese ir pareiza;</li>
            <li>Jūs esat autorizētā persona;</li>
          </ol>
          <br>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3 text-left">
          <h5>Papildus informācija</h5>
          <h6>1. Līgums par dalību izsolēs</h6>
          <p>Dalībnieka līgums nepieciešams, lai atrunātu pušu tiesības un pienākumus dalībai e-izsoles procesā.</p>
          <h6>2. Drošības depozīts</h6>
          <p>Drošības depozīta iemaksa ir nepieciešama, lai izsoles rīkotājs pārliecinātos, ka dalībnieks ir reāla,
            maksātspējīga juridiska persona. Pēc pieprasījuma drošības depozīts tiek atgriezts saskaņā ar dalībnieka
            līguma nosacījumiem.</p>
          <p>Apstiprināšu vēlāk <a [routerLink]="['/']">Doties uz sākumu</a>.</p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="w-100 pt-2">
      <h4 class="text-center">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </h4>
    </div>
  </ng-template>

  <ng-template #smallLoading>
    <i class="fa fa-spinner fa-spin ml-2" aria-hidden="true"></i>
  </ng-template>