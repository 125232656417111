import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Language, LanguageText } from './translation.models';

@Injectable()
export class TranslationService {

constructor(
    private http: HttpClient, 
    @Inject('BASE_URL') private baseUrl: string,
  ) {}
  
  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(this.baseUrl + 'Language');
  }

  getTranslationTexts(lang: string): Observable<LanguageText[]> {
    return this.http.post<LanguageText[]>(this.baseUrl + "Language/GetTranslations", {shortCode: lang})
  }

  updateLanguageText(obj:{language:string, constant: string, languageText:string} ) {
    return this.http.post<LanguageText[]>(this.baseUrl + "Language/Update", obj)
  }
}
