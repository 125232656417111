import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnsignedContract, UserRoleDTO, UserWithRoles } from './administration.models';

@Injectable()
export class AdminService {

constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
) { }

    GetAllUsers(): Observable<UserRoleDTO[]> {
        return this.http.get<UserRoleDTO[]>(this.baseUrl + 'User/GetAllUsers');
    }

    GetAll(): Observable<UserRoleDTO[]> {
        return this.http.get<UserRoleDTO[]>(this.baseUrl + 'User/GetAllUsers');
    }

    GetUserWithRoles(id: string): Observable<UserWithRoles> {
        return this.http.get<UserWithRoles>(this.baseUrl + 'User/GetUserRoles/' + id);
    }


    saveUserRoles(user: UserWithRoles): Observable<UserWithRoles> {
        return this.http.post<UserWithRoles>(this.baseUrl + 'User/UpdateUserRoles', user);
    }

    getAllTranslations(): Observable<any> {
        return this.http.get<any>(this.baseUrl + 'Language/GetAllTranslation');
    }

    getAllSignableContracts (): Observable<UnsignedContract[]> {
        return this.http.get<UnsignedContract[]>(this.baseUrl + 'Contract/GetAllUnsignedContracts');
    }
}
