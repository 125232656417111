import { UserService } from './../shared/user.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
    constructor(private router: Router,
        private userService: UserService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        if(token != null) {
            const clonedReq = req.clone({
                headers: req.headers.set('Authorization', 'bearer ' + token)
            });
            return next.handle(clonedReq)
            .pipe(
                tap(
                    succ => {
                        if (succ instanceof HttpResponse) {
                            const refreshToken = succ.headers.get('set-authorization');
                            const tokenExpired = succ.headers.get('token-expired');
                            if (refreshToken != null) {
                                localStorage.setItem('token', refreshToken);
                            }  
                            
                            if (tokenExpired != null && tokenExpired === 'true') {
                                this.userService.applicationUserSubject.next(null);
                                localStorage.removeItem('token')
                                this.router.navigateByUrl('session-expired');
                            }
                        }
                    },
                    err => {
                        if (err.status === 401) {
                            this.router.navigateByUrl('/');
                            localStorage.removeItem('token');
                            
                        }
                    }
                )
            ) 
        } else {
            return next.handle(req.clone());
        }
    }
}