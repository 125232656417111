<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="w-100 d-flex justify-content-between">
        <h4 class="mb-0">
          Mans kabinets
        </h4>

        <a class="cursor-pointer" (click)="signOut()">Izrakstīties</a>
      </div>
      <hr>
      <h5>
        Paziņojumi
      </h5>
      <hr>
      <ng-container *ngIf="!notificationsLoading; else loading">
        <ng-container *ngIf="notifications.length > 0 ; else noNotifications">
          <ng-container *ngIf="hasEmailNotification">
            <div class="col-12 p-0">
              <div class="alert alert-danger" role="alert">
                Jūsu e-pasta adrese "{{userEmail}}" nav apstiprināta. <strong><a class="alertLink"
                    [routerLink]="['confirm-email']">Apstiprināt e-pasta adresi tūlīt</a>.</strong>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="hasContractNotification">
            <div class="col-12 p-0">
              <div class="alert alert-danger" role="alert">
                Jūs vēl neēsat parakstījis līgumu, par dalību izsolē, tāpēc izsolē piedalīties nav atļauts.
                <strong><a class="alertLink" [routerLink]="['/cabinet/contract']">Parakstīt</a>! </strong>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="hasDepositNotification">
            <div class="col-12 p-0">
              <div class="alert alert-danger" role="alert">
                Jūs neēsat iemaksājis drošības depozītu.<strong><a class="alertLink"
                    [routerLink]="['/cabinet/deposit-help']">Uzzināt vairāk</a> </strong>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="hasContractNotConfirmed">
            <div class="col-12 p-0">
              <div class="alert alert-success" role="alert">
                Jūsu parakstītais līgums ir saņemts, taču tas vēl nav apstiprināts.
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let notification of newAuctionNotifications">
          <div class="col-12 p-0 d-block notifications">
            <div class="alert alert-success alert-container" role="alert">
                <button type="button" class="close btn-close" aria-label="Close" (click)="removeNewNotification(notification.Id)">
                    <span aria-hidden="true">&times;</span>
                </button>
                <strong>Jauns izsoļu īpašums</strong> {{notification.NotificaionHeader}},
                {{notification.NotificaionMessage}}. Izsoles sākuma cena: <strong>€{{notification.StartingPrice |
                number:'0.2'}}</strong>, Sākas: <strong>{{notification.AutcionStartDate | date: 'dd/MM/yyyy HH:mm'}}
                </strong>
                <strong>
                    <a class="successLink" [routerLink]="[notification.NotificaionLinkUrl]">Aplūkot</a>
                </strong>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="tabs">
        <button class="tab-button mr-2" [class.active]="activeTab === 'participating'"
          (click)="setActiveTab('participating')">
          <span class="icon hammer">


            <span class="icon-icon-auction"><span class="path1"></span><span class="path2"></span><span
                class="path3"></span><span class="path4"></span><span class="path5"></span><span
                class="path6"></span><span class="path7"></span><span class="path8"></span><span
                class="path9"></span></span></span>Izsoles, kurās jūs piedalāties</button>
        <button class="tab-button" [class.active]="activeTab === 'subscribed'"
          (click)="setActiveTab('subscribed')"><span class="icon icon-heart"></span> Abonētas izsoles</button>
      </div>

      <div class="tab-content" *ngIf="activeTab === 'participating'">
        <h5>
          Izsoles, kurās jūs piedalāties
        </h5>
        <table style="width: 100%;" class="clasic-table mb-5">
          <thead>
            <tr>
              <td><strong>Īpašums</strong></td>
              <td><strong>Aktuālais solījums</strong></td>
              <td><strong>Jūsu solījums</strong></td>
              <td><strong>Izsole beigsies</strong></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let notification of participatingAuctionNotifications">
              <tr>
                <td class="tg-0lax">
                  <span class="mobile-label">Īpašums</span>
                  {{notification.NotificaionHeader}}, {{notification.NotificaionMessage}}
                </td>
                <td class="tg-0lax">
                  <span class="mobile-label">Aktuālais solījums</span>
                  €{{notification.CurrentPrice | number:'0.2'}}
                </td>
                <td>
                  <span class="mobile-label">Jūsu solījums</span>
                  €{{notification.UserMaxBid | number:'0.2'}}
                </td>
                <td class="tg-0lax">
                  <span class="mobile-label">Izsole beigsies</span>
                  {{notification.AutcionEndDate | date: 'dd/MM/yyyy HH:mm'}}
                </td>
                <td class="tg-0lax">
                  <a class="full-width-link cursor-pointer text-center"
                    [routerLink]="[notification.NotificaionLinkUrl]">Apskatīt</a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="tab-content" *ngIf="activeTab === 'subscribed'">
        <h5>
          Abonētas izsoles
        </h5>
        <ng-container *ngFor="let notification of subscribedAuctionNotification">
          <div class="table-container">
            <table style="width: 100%;" class="custom-table">
              <tbody>
                <tr style="height: 40px;">
                  <td colspan="5">
                    <button type="button" class="close btn-close pull-right" aria-label="Close" style="height: 35px;"
                      (click)="unsubscribe(notification.PropertyId)">
                      <span aria-hidden="true" class="visually-hidden">&times;</span>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="tg-0lax">
                    <div class="status" [ngClass]="!autctionEnded ? 'active' : 'disabled'">
                      <p *ngIf="!autctionEnded" class="mb-0"><span class="icon-icon-auction"><span
                            class="path1"></span><span class="path2"></span><span class="path3"></span><span
                            class="path4"></span><span class="path5"></span><span class="path6"></span><span
                            class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                        AKTĪVA</p>
                      <p *ngIf="autctionEnded" class="mb-0"><span class="icon-icon-auction"><span
                            class="path1"></span><span class="path2"></span><span class="path3"></span><span
                            class="path4"></span><span class="path5"></span><span class="path6"></span><span
                            class="path7"></span><span class="path8"></span><span class="path9"></span></span>
                        Noslēgusies</p>
                    </div>
                  </td>
                  <td class="tg-0lax">
                <tr>
                  <td class="d-block"><strong>{{notification.NotificaionHeader}}</strong></td>
                  <td class="d-block">{{notification.NotificaionMessage}}</td>
                </tr>
                </td>
                <td class="tg-0lax">
                  <tr>
                    <td class="d-block"><strong>Sākuma cena: </strong>€{{notification.StartingPrice | number:'0.2'}}
                    </td>
                    <td class="d-block"><strong>Aktuālais solījums: </strong>€{{notification.CurrentPrice |
                      number:'0.2'}}
                    </td>
                  </tr>
                </td>
                <td class="tg-0lax">
                  <tr>
                    <td class="d-block"><strong>Sākuma datums: </strong>€{{notification.AuctionStartDate | date:
                      'dd/MM/yyyy HH:mm'}}
                    </td>
                    <td class="d-block"><strong>Beigu datums: </strong>€{{notification.AutcionEndDate | date:
                      'dd/MM/yyyy HH:mm'}}
                    </td>
                  </tr>
                </td>
                <td class="tg-0lax"> <a class="nav-link cursor-pointer btn-primary text-center"
                    [routerLink]="[notification.NotificaionLinkUrl]">Apskatīt</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>

<ng-template #noNotifications>
  <div class="col-12" role="alert">
    Jums nav neviena jauna paziņojuma
  </div>
</ng-template>