import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  date: number;
  constructor(    
    private router: Router,
    private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    this.date = new Date().getFullYear();
  }

  navigate(path: string): void {
    // Already clicked path scroling to top
    if (this.router.url === path) {
      this.viewportScroller.scrollToPosition([0,0]);
      return;
    }

    this.router.navigate([path]);

    // Scrolling instantly to top
    document.documentElement.style.scrollBehavior = "auto";
    document.body.scrollTop = 0;
    setInterval(() => document.documentElement.style.scrollBehavior = "smooth", 50);
  }
}
