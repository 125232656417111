import { Component, OnInit } from '@angular/core';
import { NewsArticle } from '../../administration.models';
import { ArticleService } from '../article.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-all-articles',
  templateUrl: './all-articles.component.html',
  styleUrls: ['./all-articles.component.scss']
})
export class AllArticlesComponent implements OnInit {

  constructor(
    private articleService: ArticleService
  ) { }
  isLoading = true;
  articles: NewsArticle[] = [];

  ngOnInit() {
    this.articleService.getAllArticles()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(data => {
        this.articles = data;
      })
  }

}
