import { WideAngleViewerComponent } from './wide-angle-viewer/wide-angle-viewer.component';
import { PropertyInfoComponent } from './auction-details/property-info/property-info.component';
import { PropertyDocumentsComponent } from './auction-details/property-documents/property-documents.component';
import { TreeQuantitiesComponent } from './auction-details/tree-quantities/tree-quantities.component';
import { BrowserModule } from '@angular/platform-browser';
import { AuctionService } from './auction.service';
import { SingleAuctionComponent } from './single-auction/single-auction.component';
import { AuctionFilterComponent } from './auction-filter/auction-filter.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuctionComponent } from './auction.component';
import { AuctionDetailsComponent } from './auction-details/auction-details.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxView360Module } from '@egjs/ngx-view360';
import { LoadingComponent } from '../loading/loading.component';
import { SmallLoadingComponent } from '../small-loading/small-loading.component';
import { LoadingModule } from '../loading/loading.module';
import { ToasterModule } from '../shared/toaster/toaster.module';
// import { CountdownModule } from 'ngx-countdown';

const routes: Routes = [
  { path: 'auction', component: AuctionComponent,
    children: [
      {path: ':id', component: AuctionDetailsComponent}
  ]
  },
  
];

@NgModule({
  imports: [
    CommonModule,
    
    ToasterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgxView360Module,
    LoadingModule
  ],
  declarations: [
    AuctionComponent,
    AuctionFilterComponent,
    SingleAuctionComponent,
    AuctionDetailsComponent,
    TreeQuantitiesComponent,
    PropertyDocumentsComponent,
    PropertyInfoComponent,
    WideAngleViewerComponent,
    
    SmallLoadingComponent
  ],
  exports: [
    RouterModule,
    AuctionFilterComponent,
    AuctionComponent,
    SingleAuctionComponent,
    WideAngleViewerComponent
  ],
  providers: [
    AuctionService
  ],
  entryComponents: [WideAngleViewerComponent]
})
export class AuctionModule { }
