<div class="container page-container mb-5">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="companyDetails" id="msform">
        <!-- progressbar -->
        <ul id="progressbar" *ngIf="step != 3">
          <li [ngClass]="step>=1 ? 'active ' : 'inactive'"><span class="description">Uzņēmuma dati</span></li>
          <li class="px" [ngClass]="step>=2 ? 'active' : 'inactive'"><span class="description">Personas dati</span></li>
          <li [ngClass]="step==3 ? 'active' : 'inactive'"><span class="description">GATAVS</span></li>
        </ul>
        <!-- fieldsets -->
        <div class="col-12 col-md-9 m-auto">
          <fieldset *ngIf="step == 1">
            <h2 class="fs-title mt-2 mb-4">Uzņēmuma dati</h2>
            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="registrationNumber">Uzņēmuma reģistrācijas numurs</label>
                  <input type="text" class="form-control" name="registrationNumber" formControlName="registrationNumber"
                    [class.is-invalid]="!companyDetails.controls['registrationNumber'].valid && companyDetails.controls['registrationNumber'].touched">
                  <span class="text-danger text-left"
                    *ngIf="companyDetails.controls['registrationNumber'].touched && companyDetails.controls['registrationNumber'].hasError('required')">
                    Reģistrācijas numuram jābūt norādītajam!
                  </span>
                  <span class="text-danger text-left"
                    *ngIf="companyDetails.controls['registrationNumber'].touched && companyDetails.controls['registrationNumber'].hasError('minlength')">
                    Reģistrācijas nummurs nav pareizi norādīts!
                  </span>
                </div>
              </div>
            </div>
            <ng-container *ngIf="companyLookupCallResult.called && !companyLookupCallResult.success">
              <div class="row">
                <div class="col-12 text-left">
                  <span class="text-danger text-left">
                    Pēc ievadītā reģistrācijas numura uzņēmums netika atrasts, lūdzu aizpildiet informāciju manuāli.
                  </span>
                </div>
                <div class="col-12 text-left">
                  <form [formGroup]="registrationForm" id="msform">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group text-left">
                          <label for="companyName">Uzņēmuma nosaukums</label>
                          <input type="text" class="form-control" name="companyName" formControlName="companyName"
                            [class.is-invalid]="!registrationForm.controls['companyName'].valid && registrationForm.controls['companyName'].touched">
                          <span class="text-danger text-left"
                            *ngIf="!registrationForm.controls['companyName'].valid && registrationForm.controls['companyName'].touched">
                            Uzņēmuma nosaukumam jābūt aizpildītam!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group text-left">
                          <label for="companyVatNumber">Uzņēmuma PVN maksātāja numurs</label>
                          <input type="text" class="form-control" name="companyVatNumber"
                            formControlName="companyVatNumber"
                            [class.is-invalid]="!registrationForm.controls['companyVatNumber'].valid && registrationForm.controls['companyVatNumber'].touched">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group text-left">
                          <label for="companyRegistrationCountry">Uzņēmuma reģistrācijas valsts</label>
                          <input type="text" class="form-control" name="companyRegistrationCountry"
                            formControlName="companyRegistrationCountry"
                            [class.is-invalid]="!registrationForm.controls['companyRegistrationCountry'].valid && registrationForm.controls['companyRegistrationCountry'].touched">
                          <span class="text-danger text-left"
                            *ngIf="!registrationForm.controls['companyRegistrationCountry'].valid && registrationForm.controls['companyRegistrationCountry'].touched">
                            Uzņēmuma reģistrācijas valstij jābūt aizpildītai!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group text-left">
                          <label for="companyLegalAddress">Uzņēmuma juridiskā adrese</label>
                          <input type="text" class="form-control" name="companyLegalAddress"
                            formControlName="companyLegalAddress"
                            [class.is-invalid]="!registrationForm.controls['companyLegalAddress'].valid && registrationForm.controls['companyLegalAddress'].touched">
                          <span class="text-danger text-left"
                            *ngIf="!registrationForm.controls['companyLegalAddress'].valid && registrationForm.controls['companyLegalAddress'].touched">
                            Uzņēmuma reģistrācijas valstij jābūt aizpildītai!
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!companyLookupCallResult.called">
              <ng-container *ngIf="!isCompanyLookupLoading; else loading">
                <input (click)="searchCompany()" type="button" name="next" class="btn btn-primary" value="Turpināt" />
              </ng-container>
            </ng-container>
            <ng-container *ngIf="companyLookupCallResult.called">
              <input (click)="next()" type="button" name="next" class="btn btn-primary" value="Turpināt" />
            </ng-container>
          </fieldset>
        </div>
      </form>
      <form [formGroup]="registrationForm" id="msform">
        <div class="col-12 col-md-9 m-auto">
          <fieldset *ngIf="step == 2">
            <h2 class="fs-title mt-2 mb-4">Dati par personu</h2>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="text-left">
                  <label for="companyName">Uzņēmuma nosaukums</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <p><strong>{{registrationForm.controls['companyName'].value}}</strong></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <label for="registrationNumber">Uzņēmuma reģistrācijas valsts</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <p><strong>{{registrationForm.controls['companyRegistrationCountry'].value}}</strong></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <label for="companyRegistrationNumber">Uzņēmuma reģistrācijas numurs</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <p><strong>{{registrationForm.controls['companyRegistrationNumber'].value}}</strong></p>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <label for="companyVatNumber">PVN maksātāja numurs</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <p><strong>{{registrationForm.controls['companyVatNumber'].value}}</strong></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <label for="companyLegalAddress">Uzņēmuma reģistrācijas adrese</label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class=" text-left">
                  <p><strong>{{registrationForm.controls['companyLegalAddress'].value}}</strong></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="username">Lietotājvārds</label>
                  <input type="text" class="form-control" name="username" formControlName="username"
                    [class.is-invalid]="showExistingUserName || !registrationForm.controls['username'].valid && registrationForm.controls['username'].touched">
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['username'].valid && registrationForm.controls['username'].touched">
                    Lietotājvārdam jābūt aizpildītam!
                  </span>
                  <span class="text-danger text-left" *ngIf="showExistingUserName">
                    Atvainojiet šāds lietotājvārds jau tiek izmantots
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="email">E-pasts</label>
                  <input type="email" class="form-control" name="email" formControlName="email"
                    [class.is-invalid]="!registrationForm.controls['email'].valid && registrationForm.controls['email'].touched">
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['email'].valid && registrationForm.controls['email'].touched">
                    E-pastam jābūt aizpildītam!
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="firstName">Vārds</label>
                  <input type="firstName" class="form-control" name="firstName" formControlName="firstName"
                    [class.is-invalid]="!registrationForm.controls['firstName'].valid && registrationForm.controls['firstName'].touched">
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['firstName'].valid && registrationForm.controls['firstName'].touched">
                    Vārdam jābūt aizpildītam!
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="surname">Uzvārds</label>
                  <input type="surname" class="form-control" name="surname" formControlName="surname"
                    [class.is-invalid]="!registrationForm.controls['surname'].valid && registrationForm.controls['surname'].touched">
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['surname'].valid && registrationForm.controls['surname'].touched">
                    Uzvārdam jābūt aizpildītam!
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="phoneNumber">Tālruņa numurs</label>
                  <input type="phoneNumber" class="form-control" name="phoneNumber" formControlName="phoneNumber"
                    [class.is-invalid]="!registrationForm.controls['phoneNumber'].valid && registrationForm.controls['phoneNumber'].touched">
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['phoneNumber'].valid && registrationForm.controls['phoneNumber'].touched">
                    Tālrunim jābūt aizpildītam!
                  </span>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="password">Parole</label>
                  <input type="password" class="form-control" name="password" formControlName="password"
                    [class.is-invalid]="!registrationForm.controls['password'].valid && registrationForm.controls['password'].touched && registrationForm.controls['passwordRepeat'].touched">
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['password'].valid && registrationForm.controls['password'].touched && registrationForm.controls['passwordRepeat'].touched">
                    Parolei jābūt aizpildītai un tai jāsakrīt ar atkārtoti ievadīto paroli!
                    <br>
                  </span>
                  <span class="text-danger text-left"
                    *ngIf="registrationForm.controls['password'].touched && registrationForm.controls['password'].hasError('minlength')">
                    Parolei jābūt vismaz 6 simbolu garai
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="passwordRepeat">Lūdzu atkārtojiet paroli</label>
                  <input type="password" class="form-control" name="passwordRepeat" formControlName="passwordRepeat"
                    [class.is-invalid]="!registrationForm.controls['passwordRepeat'].valid && registrationForm.controls['passwordRepeat'].touched">
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['passwordRepeat'].valid && registrationForm.controls['passwordRepeat'].touched">
                    Atkārtoti ievadītai parolei jābūt aizpildītai!
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-left">
                <div class="form-group mb-0">
                  <label for="authorityType">Pārstāvniecības status</label>
                  <span class="text-danger text-left"
                    *ngIf="!registrationForm.controls['authorityType'].valid && registrationForm.controls['authorityType'].touched">
                    Pārstāvniecības statusam jābūt norādītam!
                  </span>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="authorityType" formControlName="authorityType"
                      value="1">Valdes loceklis
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="authorityType" formControlName="authorityType"
                      value="2">Pilnvarota persona
                  </label>
                </div>
                <div class="form-check-inline disabled">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="authorityType" formControlName="authorityType"
                      value="3">Z/S īpašnieks
                  </label>
                </div>
                <div class="form-check-inline disabled">
                  <label class="form-check-label">
                    <input type="radio" class="form-check-input" name="authorityType" formControlName="authorityType"
                      value="4">Cits
                  </label>
                </div>
              </div>
            </div>
            <div class="row mt-3" *ngIf="registrationForm.controls['authorityType'].value === '4'">
              <div class="col-12">
                <div class="form-group text-left">
                  <label for="authorityTypeOther">Precizējiet parstāvniecības status "cits"</label>
                  <input type="text" class="form-control" name="authorityTypeOther" formControlName="authorityTypeOther"
                    [class.is-invalid]="!registrationForm.controls['authorityTypeOther'].valid && registrationForm.controls['authorityTypeOther'].touched">
                </div>
              </div>
            </div>
            <ng-container *ngIf="errors.length > 0" id="serverErrors">
              <div class="alert alert-danger">
                <span *ngFor="let er of errors">
                  {{er.description}}
                </span>
              </div>
            </ng-container>
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <input (click)="previous()" type="button" name="Atgriezties" class="btn btn-secondary"
                  value="Atgriezties" />
              </div>
              <div class="col-12 col-md-6">
                <input (click)="register()" *ngIf="!registrationIsLoading; else loading" type="button" name="Turpināt"
                  class="btn btn-primary" value="Turpināt" />
              </div>
            </div>
          </fieldset>
        </div>
      </form>
      <form [formGroup]="emailConfirmationForm" id="msform" (ngSubmit)="validateEmail()">
        <div class="col-12 col-md-9 m-auto">
          <fieldset *ngIf="step == 3" class="mb-5">
            <h2 class="fs-title mt-2 mb-4">Reģistrācija noritējusi veiksmīgi.</h2>
            <div class="row alert alert-success">
              <div class="col-12 mt-3">
                <p class="text-center mb-0">Ievadiet apstiprinājuma kodu, kas nosūtīts uz jūsu norādīto e-pasta adresi
                  "{{registrationForm.controls['email'].value}}"</p>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-8 mb-3 mb-md-0 text-left">
                <label for="emailVerificationCode" class="">Apstiprinājuma kods</label>
                <input type="text" class="form-control" name="emailVerificationCode"
                  formControlName="emailVerificationCode"
                  [class.is-invalid]="!emailConfirmationForm.controls['emailVerificationCode'].valid && emailConfirmationForm.controls['emailVerificationCode'].touched" />

                <span class="text-danger text-left"
                  *ngIf="emailValdiationCallResult.called && !emailValdiationCallResult.success">
                  e-pasta apstiprinājuma kods neizdevās, lūdzu pārbaudiet ievadīto kodu un mēģiniet vēlreiz.
                </span>
              </div>
              <div class="col-12 col-md-4" style="align-content: end;">
                <button type="submit" class="w-100 btn btn-primary"
                  *ngIf="!emailValidationLoading; else loading">Apstiprināt</button>
              </div>
              <div class="col-12 text-left pt-3">
                <p><strong>Nesaņēmi e-pastu? Ielūkojies sadaļā "SPAM".</strong> Vai <a role="button" *ngIf="!emailResendLoading; else smallLoading"
                    (click)="resendEmail()">Nosūtīt atkārtoti!</a>
                  <span *ngIf="emailResendSuccessful.called">
                    <span class="text-danger" *ngIf="!emailResendSuccessful.success"> Kaut kas nogāja greizi.</span>
                    <span class="text-success" *ngIf="emailResendSuccessful.success"> Veiksmīgi nosūtīts</span>
                  </span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-3 text-left">
                
                <ng-container *ngIf="!isCompanyLookupLoading; else loading">
                  <a class="btn btn-primary" [routerLink]="['/']">Doties uz sākumu</a>
                </ng-container>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
  </div>
</div>
  <ng-template #loading>
    <div class="w-100 pt-2">
      <h4 class="text-center">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </h4>
    </div>
  </ng-template>

  <ng-template #smallLoading>
    <i class="fa fa-spinner fa-spin ml-2" aria-hidden="true"></i>
  </ng-template>