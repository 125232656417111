<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-3 socials">
        <a (click)="navigate('/')">
          <img class="hero-logo mb-3" src="./../../assets/images/logo/Mezsole_logo_white.svg">
        </a>
        <h6 style="color: white">MEŽSOLE</h6>

        <ul class="social p-0">
          <li> <a href="https://www.facebook.com/profile.php?id=61550911393179" target="_blank"><i
                class="social-icon facebook mr-2"></i></a></li>
          <li><a href="https://www.instagram.com/mezsole" target="_blank"><i class="social-icon instagram"></i></a></li>
          <!-- <li><a href="#" target="_blank"><i class="social-icon x"></i></a></li> -->
        </ul>
      </div>

      <div class="col-12 col-md-5">
        <ul class="footer-links">
          <li><a class="nav-link pt-0" (click)="navigate('documentation')">Līgumi</a></li>
          <li><a class="nav-link" (click)="navigate('auction-regulation')">Izsoles nolikums</a></li>
          <li><a class="nav-link" (click)="navigate('privacy-policy')">Privātuma politika</a></li>
          <!-- <li><a class="nav-link" (click)="navigate('data-protection')">Datu aizsardzības politika</a></li> -->
        </ul>
      </div>
      <div class="col-12 col-md-4 contacts">
        <h6><a (click)="navigate('contacts')">KONTAKTI</a></h6>
        <ul>
          <li>
            <a href="tel:+37128270059">+371 28 270 059</a>
          </li>
          <li>
            <a href="mailto:info@mezosle.lv">info@mezsole.lv</a>
          </li>
          <li>
            <p>Jelgavas nov., Glūdas pag., Nākotne, <br>Skolas iela 18 - 2, LV-3040</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <p class="copyright">
          Copyright ©
          <script>document.write(new Date().getFullYear());</script>Visas tiesības rezervētas. Mežsole SIA
        </p>
      </div>
    </div>
  </div>
</footer>