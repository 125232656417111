import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterContainerComponent } from './toaster-container.component';
import { ToasterComponent } from './toaster.component';
import { ToasterService } from './toaster.service';
// import { CountdownModule } from 'ngx-countdown';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ToasterContainerComponent,
    ToasterComponent
  ],
  exports: [
    ToasterContainerComponent,
    ToasterComponent
  ]
})
export class ToasterModule { }
