import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuctionBid, AuctionBidWithHistory, CreateBidDto, FilteredProperties, Property, PropertyDetails, PropertyFilter, PropertyWithBid } from '../models/all.models';

@Injectable()
export class AuctionService {
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
    ) { }

    getNewestAuctions(count: number): Observable<PropertyWithBid[]> {
        return this.http.post<PropertyWithBid[]>(this.baseUrl + 'Property/Newest', count);
    }

    getFilterAuctions(filter: PropertyFilter): Observable<FilteredProperties> {
        return this.http.post<FilteredProperties>(this.baseUrl + 'Property/Filter', filter);
    }

    subscribeToAuction(id: number): Observable<any> {
        return this.http.post(this.baseUrl + 'Property/SubscribeToAuction', id);
    }

    unsubscribeToAuction(id: number): Observable<any> {
        return this.http.post(this.baseUrl + 'Property/UnsubscribeToAuction', id);
    }

    MarkAsReadNewPropertyNotification(id: number): Observable<any> {
        return this.http.post(this.baseUrl + 'Property/MarkAsReadNewPropertyNotification', id);
    }

    getPropertyById(propertyId: number): Observable<PropertyDetails>{
        return this.http.get<PropertyDetails>(this.baseUrl + 'Property/' + propertyId);
    }

    getActualPrice(propertyId: number): Observable<AuctionBidWithHistory>{
        return this.http.get<AuctionBidWithHistory>(this.baseUrl + 'Auction/actual-price/' + propertyId);
    }

    createBid(bid: CreateBidDto): Observable<AuctionBid>{
        return this.http.post<AuctionBid>(this.baseUrl + 'Auction/bid/', bid);
    }
}
