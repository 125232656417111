<ng-container *ngIf="!isloading; else loading">
<div class="container top-margin">
  <div class="row">
    <div class="col-12">
      <h3 class="mb-0">Aktualitātes</h3>
    </div>

    <div *ngFor="let item of items" class="article-container row">
      <div class="col-3">
        <img [src]="item.thumbnail" style="width:100%;" alt="Base64 Image" />
      </div>

      <div class="col-9 description">
        <div class="text-center">
          <h4>
            {{item.title}}
          </h4>
        </div>
        {{item.shortDescription}}
        <div class="link">
          <a [routerLink]="['/articles', item.id]">Lasīt vairāk...</a>
        </div>
      </div>
    </div>
    
    <div class="pagination">
      <a class="cursor-pointer pagination-link"
        *ngFor="let p of paginationArray"
        [class.active]="p === page"
        (click)="onPageChange(p)"
      >
        {{ p }}
    </a>
    </div>
  </div>
</div>
</ng-container>



<ng-template #loading>
  <div class="mt-5"></div>
  <div class="mt-5"></div>
  <div class="mt-5"></div>
  <div class="mt-5"></div>
  <div class="mt-5"></div>
  <div class="mt-5"></div>
  <div class="mt-5"></div>
  <div class="mt-5">asd</div>
  <app-loading></app-loading>
</ng-template>
