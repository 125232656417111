import { finalize } from 'rxjs/operators';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from '../admin.service';
import { UnsignedContract } from '../administration.models';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-signable-contracts',
  templateUrl: './signable-contracts.component.html',
  styleUrls: ['./signable-contracts.component.scss']
})
export class SignableContractsComponent implements OnInit, OnDestroy {

  unsignedContracts: UnsignedContract[] = [];
  isLoading = false;
  listChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription = Subscription.EMPTY;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.load();

    this.subscription = this.listChanged
      .subscribe(data => {
        if(data === true) {
          this.load();
        }
      })
  }

  load() {
    this.isLoading = true;
    this.adminService.getAllSignableContracts()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(data => this.unsignedContracts = data);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
