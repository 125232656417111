<div class="container page-container">
  <div class="row">
    <div class="col-12">
      <h1>Datu aizsardzības un privātuma politika</h1>
      <h3>Personas datu apstrāde</h3>

      <p>Jūsu datu aizsardzība ir mūsu galvenā prioritāte. Visās lapās mēs izmantojam SSL drošības standartu. Jūsu dati tiek kodēti tieši pēc pārsūtīšanas un saglabāti kodētā veidā aizsargātā datu bāzē.</p>
      <p>Mežsole ir atbildīgs par to, lai lietotāja personas dati tiktu apstrādāti saskaņā ar Datu aizsardzības regulu. (bieži saukta par GDPR) Eiropas Parlamenta un Padomes 2016. gada 27. aprīļa Regula (ES) 2016/679 par fizisku personu aizsardzību attiecībā uz personas datu apstrādi un šādu datu brīvu apriti un ar ko atceļ Direktīva 95/46/EK (Vispārīgā datu aizsardzības regula). Vairāk var lasīt Datu aizsardzības inspekcijas mājaslapā</p>
  
      <h3>Kā mēs apstrādājam jūsu personas datus?</h3>
      <p>Informācija, kas par apmeklētājiem tiek iegūta, izmantojot Mežsole vietni, tiek apstrādāta atbilstoši tās ieguves mērķim, lai nodrošinātu pakalpojumu sniegšanu</p>
    
      <h3>Pārdošanas statistikas un metadatu pārvaldība</h3>
      <p>Mūsu uzņēmumā mēs rūpīgi pārvaldām pārdošanas statistiku un metadatus, lai uzlabotu mūsu pakalpojumu kvalitāti un lietotāju pieredzi. Šie dati tiek izmantoti tikai uzņēmuma iekšienē un netiek nodoti trešajām personām bez jūsu skaidras piekrišanas. Mēs nodrošinām, ka jūsu dati tiek apstrādāti saskaņā ar visiem attiecīgajiem likumiem un noteikumiem, lai garantētu jūsu privātuma aizsardzību. Ja jums ir jautājumi par to, kā mēs apstrādājam jūsu datus, lūdzu, sazinieties ar mums.</p>
    
      <h3>Sīkdatnes</h3>
    
      <p>Sīkdatnes darbojas kā konkrētas vietnes atmiņa, ļaujot šai vietnei atcerēties Jūsu datoru vai mobilo ierīci nākamajās apmeklējuma reizēs, tai skaitā sīkdatnes var atcerēties Jūsu iestatījumus vai padarīt vietnes lietošanu ērtāku.
        Papildu informāciju par sīkdatnēm, kā arī to, kā tās iespējams pārvaldīt vai izdzēst, varat iegūt tīmekļa vietnē: www.aboutcookies.org. 
        </p>

        <h3>Kas ir sīkdatnes un kā mēs izmantojam sīkdatnes?</h3>
        <p>Mežsole vietne satur tā sauktās sīkdatnes. Sīkdatnes ir neliels teksta fails, ko datorā ievieto tīmekļa serveris un kas darbojas kā ID karte. Sīkdatnes ļauj vietnei atcerēties svarīgu informāciju, kas padara jūsu vietnes apmeklējumu ērtāku. Tāpat kā lielākā daļa citu vietņu, Mežsole izmanto sīkfailus, lai uzlabotu jūsu interneta lietošanas pieredzi šādos veidos:</p>
        <ul>
          <li>atcerētos vai esat jau piekritis tam, ka šajā tīmekļa vietnē tiek izmantotas sīkdatnes;</li>
          <li>analizētu apmeklētāju darbības tīmekļa vietnē (pieslēguma laiks, ilgums, skatītākās sadaļas);</li>
          <li>pielāgojiet mūsu pakalpojumus, lai jūs saņemtu jums atbilstošas reklāmas;</li>
        </ul>

        <p>Jums ir tiesības atsaukt savu piekrišanu, kas ļauj mums izmantot sīkfailus un līdzīgas tehnoloģijas, mainot jūsu pārlūkprogrammas iestatījumus</p>
        <p>
          Ir divu veidu sīkfaili, un Mežsole vietne izmanto abus. Viens veids, ko sauc par pastāvīgo sīkfailu, saglabā failu, kas paliek apmeklētāja datorā. To izmanto, piemēram, lai varētu pielāgot vietni apmeklētāja vēlmēm, izvēlēm un interesēm, kā arī statistiskai uzraudzībai. Zemāk mēs esam uzskaitījuši visus sīkfailus, to mērķi, domēnu, kuram tie pieder, un to kalpošanas laiku. Otro veidu sauc par sesijas sīkfailu. Kamēr apmeklētājs atrodas tīmekļa lapā, tas uz laiku tiek saglabāts apmeklētāja datora atmiņā. Sesijas sīkfaili pazūd, kad aizverat pārlūkprogrammu. Mežsole vietne cita starpā izmanto arī trešo pušu sīkfailus Google Analytics un atkārtotā mārketinga vajadzībām. Mērķis ir saprast, kā tiek izmantota mūsu vietne, un spēt to uzlabot, kā arī spēt veikt mērķtiecīgu reklāmu. Ja nevēlaties saņemt sīkfailus, varat mainīt sīkfailu iestatījumus savā pārlūkprogrammā, kā arī varat bloķēt sīkfailus. Lūdzu, ņemiet vērā, ka, bloķējot sīkfailus, jūs nevarēsit izmantot visas Mežsole vietnes funkcijas.
        </p>

        <h3>Kādus sīkfailus, tagus un pikseļus mēs izmantojam?</h3>

        <table>
          <tr>
            <td>Domēna</td>
            <td>www.mezsole.lv</td>
          </tr>
          <tr>
            <td>Mezsole.lv</td>
            <td>Vietnes funkcionalitāte</td>
          </tr>
          <tr>
            <td>LinkedIn</td>
            <td>Tiešais mārketings</td>
          </tr>
          <tr>
            <td>Facebook</td>
            <td>Tiešais mārketings</td>
          </tr>
          <tr>
            <td>Google</td>
            <td>Tiešais mārketings</td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>Veiktspējas dati</td>
          </tr>
        </table>
    
        <h3>Jūsu tiesības</h3>
        <ul>
          <li>jums ir tiesības saņemt to personas datu kopiju, ko mēs apstrādājam par jums. Šo informāciju varat iegūt, sazinoties ar mums.
          </li>
          <li>jums ir tiesības labot personas datus, kurus mēs apstrādājam par jums, ja redzat, ka tie ir nepareizi.</li>
          <li>jums ir tiesības atsaukt savu piekrišanu, kas ļauj mums izmantot sīkfailus un līdzīgas tehnoloģijas, mainot jūsu pārlūkprogrammas iestatījumu</li>
        </ul>

        <h3>Tiesības dzēst savus personas datus</h3>
        <p>Jums ir tiesības uz jūsu personas datu dzēšanu, ja:</p>
        <ul>
          <li>personas dati vairs nav nepieciešami nolūkiem, kādiem tie tika saņemti</li>
          <li>jūsu konkrētā situācija dod jums tiesības iebilst pret apstrādi, pamatojoties uz likumīgām interesēm (skatiet vairāk zemāk);</li>
          <li>personas datu apstrāde ir bijusi nelikumīga</li>
          <li>saskaņā ar ES tiesību aktiem mums ir noteikts juridisks pienākums dzēst datus.</li>
        </ul>

        <h3>Tiesības ierobežot Jūsu personas datu apstrādi</h3>
        <p>Jums ir tiesības pieprasīt, lai mēs ierobežotu jūsu datu apstrādi, ja:</p>
        <ul>
          <li>mūsu rīcībā esošie personas dati par jums ir nepareizi;</li>
          <li>apstrāde ir nelikumīga un jūs lūdzat ierobežot personas datu apstrādi, nevis tos dzēst;</li>
          <li>mums vairs nav nepieciešami personas dati apstrādes nolūkos, bet, ja tie mums joprojām ir nepieciešami juridisku prasību iesniegšanai, īstenošanai vai aizstāvībai</li>
          <li>esat iebildis pret apstrādi un apgalvojat, ka likumīgo interešu tiesiskais pamats ir nederīgs, un gaidāt šīs prasības pārbaudi.
          </li>
        </ul>

        <h3>Tiesības iebilst pret jūsu personas datu apstrādi</h3>
        <p>Jums ir tiesības iebilst pret savu datu apstrādi, ja:</p>
        <ul>
          <li>varat pierādīt, ka jūsu intereses, tiesības un brīvības attiecībā uz personas datiem ir svarīgākas par mūsu interesi apstrādāt jūsu personas datus;</li>
          <li>mēs apstrādājam jūsu personas datus tiešā mārketinga vajadzībām.</li>
        </ul>

        <h3>Jums ir tiesības uz datu pārnesamību</h3>
        <ul>
          <li>par personas datiem, ko esat mums sniedzis un</li>
          <li>ja personas datu apstrādes tiesiskais pamats ir līguma izpilde vai piekrišana.</li>
        </ul>

        <p>Mēs nosūtīsim jūsu datu kopiju standarta un mašīnlasāmā formātā jums vai jūsu noteiktai personai/organizācijai.</p>
        <h3>Kā izmantot savas tiesības?</h3>
        <p>Nosūtiet mums e-pastu uz info@mezsole.lv un mēs darīsim visu iespējamo, lai kopīgi to atrisinātu.</p>
        <h3>Kā jūs varat sazināties ar mums?</h3>
        <p class="mb-5">Juridiskā persona Mežsole SIA, Skolas iela 18-2, Nākotne, Jelgavas novads, Reģistrācijas numurs 40203581119, +371-28270059, info@mezsole.lv</p>
      </div>
</div>