import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleService } from '../../admin/posts/article.service';
import { finalize } from 'rxjs';
import { NewsArticle } from '../../admin/administration.models';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  id: number;
  isLoading = true;
  article: NewsArticle;
  constructor(
    private activatedRoute: ActivatedRoute,
    private articleService: ArticleService,
  ) { }

  ngOnInit() {
    this.id = +this.activatedRoute.snapshot.params["id"] as number;

    this.articleService.getArticle(this.id)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(data => {
      this.article = data;
    })
  }

}
