import { finalize } from 'rxjs/operators';
import { UserService } from './../../shared/user.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  wrongPass = false;
  isLoading = false;
  loginForm: UntypedFormGroup;
  userService: UserService;
  constructor(userService: UserService,
    private modalRef: BsModalRef,
    private modalService: BsModalService) {
    this.createForm();
    this.userService = userService;
    
   }

  ngOnInit() {
  }

  login() {
    this.wrongPass = false;
    this.isLoading = true;
    this.userService.login(this.loginForm.value)
    .pipe(finalize(() => this.isLoading = false))
      .subscribe(data => 
      {
        if(data !== null) {
          this.closeModal();
        }
      },
      errResponse => {
        this.wrongPass = true;        
      });
  }
  
  private createForm() {
    this.loginForm = new UntypedFormGroup({
      userName: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });
  }

  
  closeModal() {
    this.modalRef.hide();
  }
}
