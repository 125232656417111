import { BsModalRef } from 'ngx-bootstrap/modal';
import { PropertyService } from '../../property/property.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-region-modal',
  templateUrl: './new-region-modal.component.html',
  styleUrls: ['./new-region-modal.component.scss']
})
export class NewRegionModalComponent implements OnInit {
  regionForm: UntypedFormGroup;
  isLoading: boolean = false;
  refresh: Subject<void> = new Subject();
  constructor(
    private propertyService: PropertyService,
    private modalRef: BsModalRef
    ) {
    this.createForm();
  }

  ngOnInit() {
  }

  submitForm() {
    if(this.regionForm.valid) {
      this.isLoading = true;

      this.propertyService.saveRegion(this.regionForm.value)
        .pipe(finalize(() => {
          this.isLoading = false;
          this.closeModal();
        }))
        .subscribe(region => {
          this.refresh.next();
          console.log(region);
        });
      
    } else {
      this.regionForm.markAllAsTouched();
    }
  }

  closeModal() {
    this.modalRef.hide();
  }
  

  private createForm() {
    this.regionForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required)
    })
  }
}
