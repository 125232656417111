import { TreeType } from './../../../models/all.models';
import { AuctionService } from './../../../auction/auction.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { pipe, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuctionType, County, Parish, PropertyExecutionType, Region } from '../../administration.models';
import { NewCountyModalComponent } from '../../county-modals/new-region-modal/new-county-modal.component';
import { NewPropertyExecTypeModal } from '../../property-execution-type/new-region-modal/new-property-exec-type-modal.component';
import { NewRegionModalComponent } from '../../region-modals/new-region-modal/new-region-modal.component';
import { PropertyService } from '../property.service';
import { formatDate } from '@angular/common';
import { PropertyTreeQuantity } from '../../../models/all.models';
import { ImageService } from '../../Image.service';


@Component({
  selector: 'app-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.css']
})
export class EditPropertyComponent implements OnInit {
  modalRef?: BsModalRef;
  propertyForm: UntypedFormGroup;
  regionsIsLoading = false;
  isPropertyLoading = true;
  countiesIsLoading = false;
  isPropertyTreeTypesLoading = false;
  parishIsLoading = false;
  auctionTypeIsLoading = false;
  formIsLoading = false;
  PropertyExecutionTypeIsLoading = false;
  modalSubscription: Subscription = Subscription.EMPTY;
  selectedFile: File | null = null;
  sendingNotifications = false;

  auctionTypeEnum = {
    felling: 1,
    property: 2
  };


  regions: Region[];
  propertyTreeTypes: TreeType[] = [];
  counties: County[];
  parishes: Parish[];
  auctionTypes: AuctionType[]
  propertyExecutionTypes: PropertyExecutionType[];
  get treeTypes() {
    return this.propertyForm.controls["propertyTreeQuantities"] as UntypedFormArray;
  }
  
  constructor(
    private modalService: BsModalService,
    private propertyService: PropertyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auctionservice: AuctionService,
    private imageService: ImageService,
    ) { 
      this.createForm();
      
    }

  ngOnInit() {
   this.getRegions();
   this.getCounties();
   this.getPropertyExecutionType();
   this.getProperty();
   this.getTreeTypes();
   this.getParishes();
   this.getAuctionTypes();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.modalSubscription.unsubscribe();
  }

  sendNotifiations() {
    if(this.propertyForm.controls['isEnabled'].value !== true) {
      alert("Izsoļu īpašums vēl nav publicēts, lūdzu ieķeksē 'publicēt' un saglabāt pirms šo izsoļu sūtīšanas");
    }

    this.sendingNotifications = true;

    this.propertyService.SendNotifications(+this.activatedRoute.snapshot.params["id"])
      .pipe(finalize(() => this.sendingNotifications = false))
      .subscribe(() => {
        alert("izdevās");
      },
      err => alert('neizdevās, kaut kas nogāja greizi')
    )
  }

  getProperty() {
    const id = this.activatedRoute.snapshot.params["id"];
    this.auctionservice.getPropertyById(id)
      .pipe(finalize(() => this.isPropertyLoading = false))
      .subscribe(x => {
        this.propertyForm.patchValue(x);
        this.propertyForm.controls['regionId'].setValue(x.region.id);
        this.propertyForm.controls['countyId'].setValue(x.county.id);
        this.propertyForm.controls['forestState'].setValue(x.forestState)

        if(x?.propertyExecutionType?.id) {
          this.propertyForm.controls['propertyExecutionTypeId'].setValue(x.propertyExecutionType.id);
        }
        if(x?.parish?.id) {
          this.propertyForm.controls['parishId'].setValue(x.parish.id);
        }
        this.propertyForm.controls['auctionTypeId'].setValue(x.auctionType.id);
        let now = new Date(x.auctionEnds);
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        this.propertyForm.controls['auctionEnds'].setValue(now.toISOString().slice(0,16));
        this.propertyForm.controls['allowedToWorkTillDateTime'].setValue(formatDate(new Date(x.allowedToWorkTillDateTime), 'yyyy-MM-dd', 'en'));
        this.propertyForm.controls['duePayDateTime'].setValue(formatDate(new Date(x.duePayDateTime), 'yyyy-MM-dd', 'en'));
     
        x.propertyTreeQuantities.forEach(element => {
          this.createFormGroup(element);
        });

        this.updatePropertyForm(x.auctionType.id)
      })
  }

  openNewRegionModal(): void {
    this.modalRef = this.modalService.show(NewRegionModalComponent,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getRegions();
    });
  }

  openNewCountyModal(): void {
    this.modalRef = this.modalService.show(NewCountyModalComponent,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getCounties();
    });
  }

  openNewPropertyExecTypeModal(): void {
    this.modalRef = this.modalService.show(NewPropertyExecTypeModal,
      { class: 'forms-modal' });
    this.modalRef.content.refresh.subscribe(() =>{
       this.getPropertyExecutionType();
    });
  }

  createFormGroup( treeQ?: PropertyTreeQuantity) {
    const treeQuantities = this.propertyForm.controls['propertyTreeQuantities'] as UntypedFormArray;
    const form = new UntypedFormGroup({
      id: new UntypedFormControl(0),
      propertyId: new UntypedFormControl(+this.activatedRoute.snapshot.params["id"], Validators.required),
      treeTypeId: new UntypedFormControl('', Validators.required),
      quantity: new UntypedFormControl('', Validators.required)
    });

    if(treeQ) {
      form.patchValue(treeQ);
    }

    treeQuantities.push(form);
  }

  submitForm() {
    if(this.propertyForm.valid) {
      this.formIsLoading = true;

      this.propertyService.updateProperty(this.propertyForm.value)
        .pipe(finalize(() => {
          this.formIsLoading = false;
        }))
        .subscribe(data => {
          
          this.router.navigate(['admin/properties']);
        }
        );
      
    } else {
      this.propertyForm.markAllAsTouched();
      Object.keys(this.propertyForm.controls).forEach(key => {
        const controlErrors = this.propertyForm.get(key)?.errors;
        if (controlErrors != null) {
          console.log('Key: ' + key + ', Errors: ', controlErrors);
        }
      });
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onSubmit() {
    if (this.selectedFile) {
      this.imageService.uploadImage(this.selectedFile, this.propertyForm.controls["id"].value).subscribe(
        (response) => {
          console.log('Image uploaded successfully:', response);
          // Optionally, you can reset the form or display a success message.
        },
        (error) => {
          console.error('Image upload failed:', error);
          // Handle errors here, e.g., display an error message.
        }
      );
    }
  }

  private updatePropertyForm(value) {
    
    if(value === this.auctionTypeEnum.felling) {
      this.makeControlRequired('propertyExecutionTypeId');
      // this.makeControlRequired('propertyTreeQuantities');
      this.makeControlRequired('allowedToWorkTillDateTime');
      this.makeControlRequired('distanceToAGK');
      this.makeControlNonRequired('forestState');
      this.makeControlNonRequired('forestArea');
    }

    if(value === this.auctionTypeEnum.property) {
      this.makeControlNonRequired('propertyExecutionTypeId');
      // this.makeControlNonRequired('propertyTreeQuantities');
      this.makeControlNonRequired('allowedToWorkTillDateTime');
      this.makeControlNonRequired('distanceToAGK');
      this.makeControlRequired('forestState');
      this.makeControlRequired('forestArea');
    }
  }

  makeControlNonRequired(name: string) {
    this.propertyForm.get(name)?.
    setValidators([]); // Remove validators
    this.propertyForm.get(name)?.updateValueAndValidity(); // Update validity
  }

  // Method to add required validator back to regionId
  makeControlRequired(name: string) {
    this.propertyForm.get(name)?.setValidators([Validators.required]); // Add required validator
    this.propertyForm.get(name)?.updateValueAndValidity(); // Update validity
  }

  private createForm(): void {
    this.propertyForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      regionId: new UntypedFormControl('', Validators.required),
      countyId: new UntypedFormControl('', Validators.required),
      auctionTypeId: new UntypedFormControl('', Validators.required),
      parishId: new UntypedFormControl(''),
      propertyName: new UntypedFormControl('', Validators.required),
      forestState: new UntypedFormControl('', Validators.required),
      kadestsNumber: new UntypedFormControl('', Validators.required),
      propertyExecutionTypeId: new UntypedFormControl(Validators.required),
      propertyConditionMark: new UntypedFormControl('', Validators.required),
      propertyArea: new UntypedFormControl('', Validators.required),
      forestArea: new UntypedFormControl('', Validators.required),
      distanceToAGK: new UntypedFormControl('', Validators.required),
      startingPrice: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      minimalBiddingStep: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      maximalBiddingStep: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
      duePayDateTime: new UntypedFormControl('', Validators.required),
      allowedToWorkTillDateTime: new UntypedFormControl('', Validators.required),
      auctionEnds: new UntypedFormControl('', Validators.required),
      startDate: new UntypedFormControl('', Validators.required),
      isEnabled: new UntypedFormControl(false),
      virtualTourUrl: new UntypedFormControl('', Validators.required),
      youtubeVideoUrl: new UntypedFormControl('', Validators.required),
      propertyTreeQuantities: new UntypedFormArray([]),
      thumbnail: new UntypedFormControl(''),      
    })
  }

  private getRegions() {
    this.regionsIsLoading = true;
    this.propertyService.getAllRegions()
      .pipe(finalize(() => this.regionsIsLoading = false))
      .subscribe(data => {
        this.regions = data;
      })
  }

  private getAuctionTypes() {
    this.auctionTypeIsLoading = true;
    this.propertyService.GetAllAuctionTypes()
      .pipe(finalize(() => this.auctionTypeIsLoading = false))
      .subscribe(data => {
        this.auctionTypes = data;
      })
  }


  private getParishes() {
    this.parishIsLoading = true;
    this.propertyService.getAllParishes()
      .pipe(finalize(() => this.parishIsLoading = false))
      .subscribe(data => {
        this.parishes = data;
      })
  }

  private getTreeTypes() {
    this.isPropertyTreeTypesLoading = true;
    this.propertyService.getAllTreeTrypes()
      .pipe(finalize(() => this.isPropertyTreeTypesLoading = false))
      .subscribe(data => {
        this.propertyTreeTypes = data;
      })
  }

  private getCounties() {
    this.countiesIsLoading = true;
    this.propertyService.getAllCounties()
      .pipe(finalize(() => this.countiesIsLoading = false))
      .subscribe(data => {
        this.counties = data;
      });
  }

  private getPropertyExecutionType() {
    this.PropertyExecutionTypeIsLoading = true;
    this.propertyService.getAllExecutionTypes()
      .pipe(finalize(() => this.PropertyExecutionTypeIsLoading = false))
      .subscribe(data => {
        this.propertyExecutionTypes = data;
      });
  }
}
