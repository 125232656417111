<div class="row mt-3">
  <div class="col-12">
    <h2>Lietotāju saraksts</h2>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Vārds</th>
          <th scope="col">Uzvārds</th>
          <th scope="col">Kompānija</th>
          <th scope="col">Reģistrācijas nr.</th>
          <th scope="col">e-pasts</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!isloading; else loading">
          <tr *ngFor="let user of users">
            <td>{{user.firstName}}</td>
            <td>{{user.surname}}</td>
            <td>{{user.companyName}}</td>
            <td>{{user.companyRegistrationNumber}}</td>
            <td>{{user.email}}</td>
            <a [routerLink]="'/admin/user'" [state]="user">Pārvaldīt</a>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #loading>
  <tr>
    <td>
      <h4 class="text-center">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </h4>
  </td>
  </tr>
</ng-template>