import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuctionModule } from '../../auction/auction.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactService } from './contact.service';
import { LoadingComponent } from '../../loading/loading.component';
import { ToasterModule } from 'src/app/shared/toaster/toaster.module';

@NgModule({
  imports: [
    CommonModule,
    AuctionModule,
    FormsModule,
    ReactiveFormsModule,
    ToasterModule,
    CarouselModule.forRoot(),
    TranslateModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
    ])
  ],
  declarations: [HomeComponent],
  providers: [ContactService]
})
export class HomeModule { }
