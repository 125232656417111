import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auction-regulation',
  templateUrl: './auction-regulation.component.html',
  styleUrls: ['./auction-regulation.component.scss']
})

export class AuctionRegulationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
