<div class="row">
  <div class="col-12"><h2>Labot Novadu</h2></div>
  <div class="col-6">
    <form [formGroup]="regionForm" (ngSubmit)="submitForm()">
      <div class="form-group">
        <label for="name">Novada nosaukums<span class="text-danger">*</span></label>
        <input type="text" class="form-control" id="name" formControlName="name"
          [class.is-invalid]="!regionForm.controls['name'].valid && regionForm.controls['name'].touched">
        <span class="text-danger" *ngIf="!regionForm.controls['name'].valid && regionForm.controls['name'].touched">
          Novada nosaukums jābūt aizpildītam!
        </span>
      </div>  
      <div class="d-md-flex justify-content-between">
        <ng-container *ngIf="!isLoading; else loading">
          <button type="submit" class="btn btn-primary">
            Saglabāt
          </button>
        </ng-container>
      </div>
    </form>   
  </div>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>