import { finalize } from 'rxjs/operators';
import { AdminService } from './../../admin.service';
import { UserRoleDTO, UserWithRoles } from './../../administration.models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit {

  isLoading = false;
  userWithRoles: UserWithRoles;
  form: UntypedFormGroup;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    const user = new UserRoleDTO(history.state)

    if (user.id) {
      this.isLoading = true;
      this.adminService.GetUserWithRoles(user.id)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(data => {
          this.userWithRoles = data;
          this.createForm();
        })
    }
  }

  private createForm() {
    const rollArray: UntypedFormArray = new UntypedFormArray([])

    this.userWithRoles.roles.forEach(x => {
      rollArray.push(new UntypedFormGroup({
        id: new UntypedFormControl(x.id),
        name: new UntypedFormControl(x.name),
        selected: new UntypedFormControl(x.selected),
      }))
    });

    this.form = new UntypedFormGroup({
      userId: new UntypedFormControl(this.userWithRoles.user.id),
      roles: rollArray
    });
  }

  saveRoles() {
    this.isLoading = true;
    this.adminService.saveUserRoles(this.form.value)
    .pipe(finalize(() => this.isLoading = false))
    .subscribe(data => {
      this.userWithRoles = data;
      this.createForm();
    })
  }
  getControls() {
    return (<UntypedFormArray>this.form.get('roles')).controls;
  }
}
