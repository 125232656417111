import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/user.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
  userEmail = "";
  userSubscription = Subscription.EMPTY
  emailConfirmationForm: UntypedFormGroup;
  emailValidationLoading = false;
  emailResendLoading = false;
  emailValdiationCallResult = { called: false, success: false };
  emailResendSuccessful = { called: false, success: false };
  constructor(
    private userService: UserService,
    private router: Router) {
    this.createConfirmationForm();
  }

  ngOnInit() {
    this.userSubscription = this.userService.applicationUserSubject
      .subscribe(data => this.userEmail = data?.email);
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  private createConfirmationForm() {
    this.emailConfirmationForm = new UntypedFormGroup({
      emailVerificationCode: new UntypedFormControl('', Validators.required),
    });
  }

  resendEmail() {
    this.emailResendLoading = true;
    this.userService.resendConfirmationEmail()
      .pipe(
        finalize(() => {
          this.emailResendLoading = false;
          this.emailResendSuccessful.called = true;
        })
      )
      .subscribe(data => {
        this.emailResendSuccessful.success = data;
      }
        , err => {
          this.emailResendSuccessful.success = false;
        })
  }

  validateEmail() {
    this.emailValidationLoading = true;
    this.userService.confirmEmailAddress(this.emailConfirmationForm.value)
      .pipe(
        finalize(() => {
          this.emailValdiationCallResult.called = true;
          this.emailValidationLoading = false;
        })
      )
      .subscribe(success => {
        this.emailValdiationCallResult.success = success;
        if (success) {
          // TO RELOAD NOTIFICATIONS, and all subscriptions
          this.userService.emitSameUserValue();
          setTimeout(() => this.router.navigate(['cabinet/contract/']), 5000);
        }
      }
    )
  }
}
