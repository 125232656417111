<div class="row">
  <div class="col-12"><h2>Labot Novadu</h2></div>
  <div class="col-6">
    <form [formGroup]="countyForm" (ngSubmit)="submitForm()">
      <div class="form-group">
        <label for="name">Novada nosaukums<span class="text-danger">*</span></label>
        <input type="text" class="form-control" id="name" formControlName="name"
          [class.is-invalid]="!countyForm.controls['name'].valid && countyForm.controls['name'].touched">
        <span class="text-danger" *ngIf="!countyForm.controls['name'].valid && countyForm.controls['name'].touched">
          Novada nosaukums jābūt aizpildītam!
        </span>
      </div>
  
      <div class="d-md-flex justify-content-between">
        <ng-container *ngIf="!isLoading; else loading">
          <button type="submit" class="btn btn-primary">
            Saglabāt
          </button>
        </ng-container>
      </div>
    </form>
    <div class="col-12 pl-0 pt-3"><h2>Labot attēlu</h2></div>
    <div class="custom-file">
      <input type="file" class="custom-file-input" id="validatedCustomFile" [accept]="requiredFileType"
      (change)="onFileSelected($event)" #fileUpload>
      <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
      <div class="invalid-feedback">Example invalid custom file feedback</div>
    </div>    
  </div>
</div>

<img id="ItemPreview" src="">
<div class="row">
  <div class="col-6">
    <button (click)="upload()" class="btn btn-primary">Saglabāt attēlu</button>
  </div>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>