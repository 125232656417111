<div class="cookie-popup" *ngIf="showCookieBanner">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-9">
        Šī tīmekļa vietne izmanto sīkdatnes. Piekrītot sīkdatņu izmantošanai, tiks nodrošināta tīmekļa vietnes optimāla darbība. Turpinot vietnes apskati Jūs piekrītat, ka izmantosim sīkdatnes Jūsu ierīcē. Savu piekrišanu Jūs jebkurā laikā varat atsaukt, nodzēšot saglabātās sīkdatnes. 
        Plašākai informācijai varat iepazīties ar <a (click)="navigate('data-protection')" class="link">datu aizsardzības politika</a> kā arī mūsu <a class="link" (click)="navigate('privacy-policy')">privātuma politiku</a>.
      </div>
      <div class="col-md-3 text-md-right">
        <button id="acceptBtn" class="btn btn-primary" (click)="acceptCookies()">Iepazinos un piekrītu</button>
      </div>
    </div>
  </div>
</div>