<p>
  asd
</p>
<ng-container *ngIf="!isLoading else loading">  
  <ngx-view360 class="view360-container is-1by1" id="c" [canvasClass]="'what'" [options]="options" />
</ng-container>

<ng-template #loading>
Resurs tiek ielādēts
</ng-template>
