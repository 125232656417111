import { finalize } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { Subscription } from 'rxjs';
import { NewAuctionNofitication, ParticipatingAuctionNotification, SubscribedAuctionNotification } from '../../shared/user.models';
import { AuctionService } from '../../auction/auction.service';

@Component({
  selector: 'app-cabinet-info',
  templateUrl: './cabinet-info.component.html',
  styleUrls: ['./cabinet-info.component.scss']
})
export class CabinetInfoComponent implements OnInit, OnDestroy {
  hasEmailNotification = false;
  hasContractNotification = false;
  hasDepositNotification = false;
  hasContractNotConfirmed = false;

  notificationsLoading = false;
  notifications: string[] = [];
  subscribedAuctionNotification: SubscribedAuctionNotification[] = [];
  participatingAuctionNotifications: ParticipatingAuctionNotification[] = [];
  newAuctionNotifications: NewAuctionNofitication[] = [];
  userSubscription = Subscription.EMPTY;
  userEmail = "";
  constructor(private userService: UserService, private auctionService: AuctionService) { }

  ngOnInit() {
    this.notificationsLoading = true;
    this.userService.getUserNotifications()
      .pipe(finalize(() => this.notificationsLoading = false))
      .subscribe(data => {
        this.notifications = data.StaticNotifications;
        this.newAuctionNotifications = data.Notifications.filter(x => x.NotificationType === 1);
        this.subscribedAuctionNotification = data.Notifications.filter(x => x.NotificationType === 2);
        this.participatingAuctionNotifications = data.Notifications.filter(x => x.NotificationType === 3);
        this.setNotificationFlags();
      },
        err => console.log(err));

    this.userSubscription = this.userService.applicationUserSubject
      .subscribe(data => this.userEmail = data?.email);

    this.userService.getMyAccountAuctions()
      .subscribe(data => console.log(data));
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  private setNotificationFlags() {
    this.hasEmailNotification = this.notifications.includes("EmailConfirmation");
    this.hasDepositNotification = this.notifications.includes("HasPaidDeposit");
    this.hasContractNotification = this.notifications.includes("HasSignedContract");
    this.hasContractNotConfirmed = this.notifications.includes("ContractNotConfirmed");
  }

  signOut() {
    this.userService.logout();
  }

  activeTab: string = 'participating'; // Default active tab

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  unsubscribe(id: number) {
    this.auctionService.unsubscribeToAuction(id)
      .pipe(finalize(() => {
        this.subscribedAuctionNotification = this.subscribedAuctionNotification.filter(x => x.PropertyId !== id);
      }))
      .subscribe();
  }

  removeNewNotification(id: number) {
    this.auctionService.MarkAsReadNewPropertyNotification(id)
      .pipe(finalize(() => {
        this.newAuctionNotifications = this.newAuctionNotifications.filter(x => x.Id !== id);
      }))
      .subscribe();
  }
}


