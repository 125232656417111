import { PropertyService } from './../admin/property/property.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AuctionService } from '../auction/auction.service';
import { PropertyFilter, PropertyWithBid } from '../models/all.models';
import { Region } from '../admin/administration.models';

@Component({
  selector: 'app-all-auctions',
  templateUrl: './all-auctions.component.html',
  styleUrls: ['./all-auctions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllAuctionsComponent implements OnInit, OnDestroy {
  isLoadingProperties = true;
  regionsIsLoading = true;
  newestProperties: PropertyWithBid[] = [];
  regions: Region[];
  auctionFilter = new PropertyFilter();
  hasMore = false;
  currentSkip = 0;
  currentTake = 4;

  @ViewChild('floatingContainer') floatingContainer!: ElementRef<HTMLVideoElement>;

  constructor(
    private auctionService: AuctionService,
    private propertyService: PropertyService,
    private cdr: ChangeDetectorRef
  ) { }
  ngOnDestroy(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.checkScreenSize();
    this.auctionFilter.skip = 0;
    this.auctionFilter.take = this.currentTake * 2;
    this.getAuctions();
    this.getRegions();
    this.currentSkip = this.currentTake;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const screenWidth = window.innerWidth;
    console.log(screenWidth);
    if(screenWidth >= 576 && screenWidth < 992){
      this.currentTake = 3;
      return;
    }
    this.currentTake = 4;
  }

  onChange() {
    this.currentSkip = 0;
    this.auctionFilter.skip = 0;
    this.auctionFilter.take = this.currentTake * 2;
    this.getAuctions();
    this.currentSkip = this.currentTake * 2;
  }

  private getRegions() {
    this.regionsIsLoading = true;
    this.propertyService.getAllCounties()
      .pipe(finalize(() => {
        this.regionsIsLoading = false;
        this.cdr.detectChanges();
      }))
      .subscribe(data => {
        this.regions = data;
        
      })
      
  }

  getAuctions() {
    this.isLoadingProperties = true;
    this.auctionService.getFilterAuctions(this.auctionFilter)
    .pipe(
      finalize(() =>{ 
        this.isLoadingProperties = false;
        this.cdr.detectChanges();}
        )
    )
    .subscribe(data => {
      this.newestProperties = data.properties;
      this.hasMore = data.hasMore;
    });
    
  }

  toggleFilterOption(state: boolean) {
    if (state) {
      this.floatingContainer.nativeElement.style.right = '0';
    }
    else {
      this.floatingContainer.nativeElement.style.right = '-310px';
    }
    // openButton.addEventListener('click', function() {
      
    // });

    // closeButton.addEventListener('click', function() {
    //   floatingContainer.style.right = '-300px';
    // });
  }

  loadMore() {
    // this.isLoadingProperties = true;
    this.currentSkip = this.currentSkip + this.currentTake;
    this.auctionFilter.take = this.currentTake;
    this.auctionFilter.skip = this.currentSkip;
    this.auctionService.getFilterAuctions(this.auctionFilter)
    .pipe(
      finalize(() => {
        this.isLoadingProperties = false;
        this.cdr.detectChanges();}
      )
    )
    .subscribe(data => {
      data.properties.forEach(element => {
        this.newestProperties.push(element);
      });
      this.hasMore = data.hasMore;
      this.cdr.detectChanges();
    });
  }
}

