import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../../admin/posts/article.service';
import { NewsArticle } from '../../admin/administration.models';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-all-articles',
  templateUrl: './all-articles.component.html',
  styleUrls: ['./all-articles.component.scss']
})
export class AllArticlesComponent implements OnInit {

  items: NewsArticle[] = [];
  page = 1;
  pageSize = 4;
  totalItems = 0;
  isloading = true;
  paginationArray: number[] = [];

  constructor(private dataService: ArticleService) {}

  ngOnInit(): void {
    this.loadItems();
  }

  loadItems(): void {
    this.isloading = true;
    this.dataService.GetPaginatedArticles(this.page, this.pageSize)
    .pipe(
      finalize(() => this.isloading = false)
    )
    .subscribe((response) => {
      this.items = response.data;
      this.page = response.page;
      this.pageSize = response.pageSize;
      this.totalItems = response.totalItems;

      this.generatePaginationArray();
    });
  }

  onPageChange(page: number): void {
    if(this.page === page) {
      return;
    }
    this.page = page;
    this.loadItems();
  }

  generatePaginationArray(): void {
    const totalPages = Math.ceil(this.totalItems / this.pageSize);
    this.paginationArray = Array.from({ length: totalPages }, (_, i) => i + 1);
  }

}
