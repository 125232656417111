import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FileDto } from '../../../models/all.models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../../../user/login/login.component';

@Component({
  selector: 'app-property-documents',
  templateUrl: './property-documents.component.html',
  styleUrls: ['./property-documents.component.scss']
})
export class PropertyDocumentsComponent implements OnInit {

  @Input() files: FileDto[]
  @Input() isUserLoggedId = false;
  
  modalRef?: BsModalRef;
  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
  }

  openLoginModal(): void {
    this.modalRef = this.modalService.show(LoginComponent,
      { class: 'registration-modal' });
  }

  openDocument(id: number) {
    if(!this.isUserLoggedId) {
      this.openLoginModal();
      return;
    }
    const upload$ = this.http.get(this.baseUrl + "File/DownloadFile/" + id.toString(), { responseType: "arraybuffer" });
    upload$.subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    })
  }
}
