<div class="site-navigation" [class.active]="isActive">
  <input type="checkbox" #navBarToggler id="nav-bar-toggler">
  <label for="nav-bar-toggler" class="menu-toggle-button">
    <span></span>
    <span></span>
    <span></span>
  </label>
  <nav>
    <a (click)="navigate('/')">
      <img class="hero-logo" src="./../../assets/images/logo/Mezsole_logo_white.svg" style="width: 55px;" *ngIf="!isActive">
      <img class="hero-logo" src="./../../assets/images/logo/Mezsole_logo_yellow.svg" style="width: 55px;" *ngIf="isActive">
    </a>
    <div class="primary-menu desktop">
      <div class="dropdown" (mouseenter)="toggleDropdown(true)" (mouseleave)="toggleDropdown(false)">
        <a class="nav-link header-height" (click)="navigate('/')">Meža Īpašniekiem<i class="arrow down"></i></a>
        <div class="dropdown-menu" [ngClass]="{ 'show': isDropdownOpen }">
          <a (click)="navigateToOwnerSection('sectionGuarantee')" class="dropdown-item cursor-pointer">Mežsoles garantijas</a>
          <!-- <a (click)="navigateToOwnerSection('sectionFeedBack')" class="dropdown-item cursor-pointer">Citu īpašnieku pieredze</a> -->
          <a (click)="navigateToOwnerSection('contact')" class="dropdown-item cursor-pointer">Pieteikt izsoli</a>
        </div>
      </div>
      <a class="nav-link header-height" (click)="navigate('./auctions')">Izsoles</a>
      <ng-container *ngIf="canAccessAdminPanel">
        <a class="nav-link header-height" (click)="navigate('admin')">Admin</a>
      </ng-container>
    </div>
    <div class="primary-menu mobile">
      <div class="dropdown">
        <a class="nav-link" (click)="navigate('/')">Meža Īpašniekiem</a>
        <div class="secondary-items pl-3">
          <a (click)="navigateToOwnerSection('sectionGuarantee', true)">Mežsoles garantijas</a>
          <!-- <a (click)="navigateToOwnerSection('sectionFeedBack', true)">Citu īpašnieku pieredze</a> -->
          <a (click)="navigateToOwnerSection('contact', true)">Pieteikt izsoli</a>
          </div>
      </div>
      <a class="nav-link" (click)="navigate('auctions')">Izsoles</a>
      <ng-container *ngIf="canAccessAdminPanel">
        <a class="nav-link" (click)="navigate('admin')">Admin</a>
      </ng-container>
    </div>
    <div class="secondary-menu">
      <ng-container *ngIf="currentApplicationUser === null">
        <a class="nav-link cursor-pointer btn" (click)="openLoginModal()">Ienākt</a>
      </ng-container>
      <ng-container *ngIf="currentApplicationUser !== null">
        <a class="nav-link cursor-pointer btn d-flex" [routerLink]="['/cabinet']">Kabinets<span class="notification-badge ml-1" *ngIf="notificationCount > 0">{{notificationCount}}</span></a>
      </ng-container>
    </div>
  </nav>
  <div class="mobile-overlay"></div>
</div>
<ng-template #loading>
  <h4>
    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
  </h4>
</ng-template>