<div class="modal-header">
  <h4 class="modal-title pull-left">Jauns regions</h4>
  <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="regionForm" (ngSubmit)="submitForm()">
    <div class="form-group">
      <label for="name">Reģiona nosaukums<span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="name" formControlName="name"
        [class.is-invalid]="!regionForm.controls['name'].valid && regionForm.controls['name'].touched">
      <span class="text-danger" *ngIf="!regionForm.controls['name'].valid && regionForm.controls['name'].touched">
        Reģiona nosaukums jābūt aizpildītam!
      </span>
    </div>

    <div class="d-md-flex justify-content-between">
      <button type="button" class="btn btn-secondary" (click)="closeModal()">
        Atcelt
      </button>
      <ng-container *ngIf="!isLoading; else loading">
        <button type="submit" class="btn btn-primary">
          Pievienot
        </button>
      </ng-container>
    </div>
  </form>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>