import { finalize } from 'rxjs/operators';
import { TranslationService } from './../../../shared/translation.service';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-update-translation',
  templateUrl: './update-translation.component.html',
  styleUrls: ['./update-translation.component.css']
})
export class UpdateTranslationComponent implements OnInit {

  languageForm: UntypedFormGroup;
  isLoading: boolean = false;
  refresh: Subject<void> = new Subject();
  @Input() language: string;

  
  constructor(
    private modalRef: BsModalRef,
    private translationService: TranslationService,
    ) {
    }
    
    ngOnInit() {
    console.log(this.language);
    this.createForm();
  }

  submitForm() {
    if(this.languageForm.valid) {
      this.isLoading = true;

      this.translationService.updateLanguageText(this.languageForm.value)
        .pipe(finalize(() => {
          this.isLoading = false;
          this.closeModal();
        }))
        .subscribe(() => {
          this.refresh.next();
        });

    } else {
      this.languageForm.markAllAsTouched();
    }
  }

  closeModal() {
    this.modalRef.hide();
  }
  

  createForm() {
    this.languageForm = new UntypedFormGroup({
      language: new UntypedFormControl('', Validators.required),
      languageText: new UntypedFormControl('', Validators.required),
      constant: new UntypedFormControl('', Validators.required),
    })
  }
}

