
  <td>{{contract.firstName}} {{contract.surname}}</td>
  <td>{{contract.companyName}}</td>
  <td>{{contract.email}}</td>
  <td><a class="link" role="button" (click)="openDocument(contract.id, contract.fileName)">{{contract.fileName}}</a></td>
  <td><button *ngIf="!isLoading; else loading" class="btn btn-primary" (click)="confirmContract(contract.applicationUserId)">APSTIPRINĀT</button></td>

  
<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>