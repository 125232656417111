import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRoleDTO } from './administration.models';


@Injectable()
export class ImageService {

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
    ) { }

    uploadImage(file: File, id: number): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);

      return this.http.post<string>(this.baseUrl +'File/upload-county-thumbnail/'+id, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }
}
