import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../../models/all.models';

@Injectable()
export class ContactService {
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
    ) { }
    
    PostContact(contact: Contact): Observable<Contact>{
        return this.http.post<Contact>(this.baseUrl + 'Contact/Create/', contact);
    }
}