import { finalize } from 'rxjs/operators';
import { AdminService } from './../../admin.service';
import { Component, OnInit } from '@angular/core';
import { UserRoleDTO } from '../../administration.models';

@Component({
  selector: 'app-view-all-users',
  templateUrl: './view-all-users.component.html',
  styleUrls: ['./view-all-users.component.css']
})
export class ViewAllUsersComponent implements OnInit {
  users: UserRoleDTO[] = [];
  isloading = true;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.GetAllUsers()
      .pipe(finalize(() => this.isloading = false))
      .subscribe(data => this.users = data);
  }

}
