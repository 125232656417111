<div class="container">
  <div class="row">
    <div class="col-12 pb-3">
      <h4>
        Līguma parakstīšana
      </h4>
      <hr>
      <p>
        Uz jūsu norādīto e-pastu tika nosūtīts dokuments, ko nepieciešams parakstīt.
      </p>
      <h5>Parakstīt ar e-parakstu</h5>
      <p>Lai iesniegtu dokumentu, kas ticis parakstīts izmantojot elektronisko parakstu, nospiediet uz pogas Meklēt failu.
        Atvērtajā dialogā izvēlieties parakstīto dokumentu. Kad dokuments ir pareizi ielasīts spiediet pogu "Augšupielādēt".
      </p>
    </div>
    <div class="col-12 pb-3" *ngIf="error"><div class="alert alert-danger ">Kaut kas nogāja greizi, lūdzu mēģiniet vēlāk!</div></div>
    <ng-container *ngIf="uploadProgress != 100; else success">
    <div class="col-12 col-md-8">
      <div class="custom-file">
        <input type="file" class="custom-file-input" [ngClass]="uploadValid ? '' : 'is-invalid ng-touched'" id="validatedCustomFile" (change)="onFileSelected($event)" #fileUpload>
        <label class="custom-file-label" for="validatedCustomFile">{{fileName.length ? fileName : 'Meklēt failu'}}</label>
      </div>
        <p class="text-danger" *ngIf="!uploadValid">
          Nav atlasīts neviens fails
        </p>
      </div>
    <div class="col-12 col-md-4">
          <ng-container *ngIf="!isLoading; else loading">
            <button (click)="upload()" class="btn btn-primary">Augšupielādēt</button>
          </ng-container>
        </div>
      </ng-container>
    <div class="col-12 pb-3">
      <p><strong>Atlasītā faila nosaukums: </strong>{{fileName}}</p>
    </div>   
    <div class="col-12 pt-3 pb-5">
      <h5>Vēlies parakstīt tradicionāli ar melnu uz balta? - līguma parakstīšanai bez e-paraksta</h5>
      <p>Parakstītus e-pastā saņemto līgumu divus eksemplārus nosūtīt pa pastu uz SIA mežsole juridisko adresi, Skolas iela 18-2, Nākotne, Jelgavas novads, LV-3040.
      </p>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>

<ng-template #success>
  <div class="w-100 pt-2 text-success">
    <h4 class="text-center">
      Dokumenta augšupielāde notikusi veiksmīgi, jūs tiekat maršrutēts uz "Mans Kabinets" <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>