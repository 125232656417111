<div class="container page-container">
  <div class="row">
    <div class="col-12">
      <h1 class="mb-4">Dokumentācija</h1>
      <div class="d-flex">
        <img class="icon-download" src="../../../../assets/images/icon-image/icon-doc.png" />
        <a href="./../../../assets/docs/Cirsmu pirkuma līgums.pdf" target="_blank">Cirsmu pirkuma līgums</a>
      </div>
      <div class="d-flex">
        <img class="icon-download" src="../../../../assets/images/icon-image/icon-doc.png" />
        <a href="./../../../assets/docs/Mežsole Dalībnieka līgums.pdf" target="_blank">Mežsole Dalībnieka līgums</a>
      </div>
      <div class="d-flex">
        <img class="icon-download" src="../../../../assets/images/icon-image/icon-doc.png" />
        <a href="./../../../assets/docs/Nekustamā īpašuma pirkuma līgums.pdf" target="_blank">Nekustamā īpašuma pirkuma līgums</a>
      </div>
      <div class="d-flex">
        <img class="icon-download" src="../../../../assets/images/icon-image/icon-doc.png" />
        <a href="./../../../assets/docs/Pārdevēja pakalpojumu līgums.pdf" target="_blank">Pārdevēja pakalpojumu līgums</a>
      </div>
      
    </div>
  </div>
</div>