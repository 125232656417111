import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {
  showCookieBanner = true;
  
  constructor(    
    private router: Router,
    private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    var cookieConsent = localStorage.getItem("cookie-accept");

    if(cookieConsent) {
      this.showCookieBanner = false;
    }
  }

  acceptCookies() {
    localStorage.setItem('cookie-accept', 'true');
    this.showCookieBanner = false;
  }

  navigate(path: string): void {
    // Already clicked path scroling to top
    if (this.router.url === path) {
      this.viewportScroller.scrollToPosition([0,0]);
      return;
    }

    this.router.navigate([path]);

    // Scrolling instantly to top
    document.documentElement.style.scrollBehavior = "auto";
    document.body.scrollTop = 0;
    setInterval(() => document.documentElement.style.scrollBehavior = "smooth", 50);
  }

}
