import { Component, Input, OnInit } from '@angular/core';
import { PropertyDetails } from '../../../models/all.models';

@Component({
  selector: 'app-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.css']
})
export class PropertyInfoComponent implements OnInit {

  @Input() property: PropertyDetails
  constructor() { }

  ngOnInit() {
  }

}
