import { UpdateTranslationComponent } from './update-translation/update-translation.component';
import { AdminService } from './../admin.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.css']
})
export class TranslationsComponent implements OnInit {
   translations = [];
   allProps: string[] = [];
   pages: Page[];
   data: any;
   currentPage: Page;
   private pageSize = 5;
   
  modalRef?: BsModalRef;
  constructor(private adminService: AdminService, 
    private modalService: BsModalService,) {
    
      this.getTranslations();
   }

  ngOnInit() {
  }

  private getTranslations() {
    this.adminService.getAllTranslations().subscribe(data => {
      this.allProps = Object.getOwnPropertyNames(data);
      this.setPages(data);
      this.setPage(1);
    })
  }

  private setPages(data: any): void {
    this.data = data;
    this.allProps = Object.getOwnPropertyNames(data);
    let pageCount = Math.ceil(this.allProps.length / this.pageSize);
    let result = [];
    for (var i = 1; i <= pageCount; i +=1){
      var page = new Page();
      page.pageNumber = i;
      page.pageItems = this.getPageItems(i);

      result.push(page);
    }

    this.pages = result;
  }

  openTranslationUpdateModal(pageItem:string, language: string, constant:string): void {
    this.modalRef = this.modalService.show(
      UpdateTranslationComponent,
      { class: 'forms-modal' });

      this.modalRef.content.languageForm.controls['languageText'].setValue(pageItem);
      this.modalRef.content.languageForm.controls['language'].setValue(language);
      this.modalRef.content.languageForm.controls['constant'].setValue(constant);
    this.modalRef.content.refresh.subscribe(() =>{
       this.getTranslations();
    });
  }

  private getPageItems(pageNumber: number): PageItem[] {
    const items: PageItem[] = [];

    const start = (pageNumber -1) * this.pageSize;
    const end = pageNumber * this.pageSize < this.allProps.length ? pageNumber * this.pageSize : this.allProps.length;
    const pageConsts = this.allProps.slice(start, end);

    pageConsts.forEach(c => {
      var constantItems = this.data[c];
      const item = new PageItem();

      item.constant = c;
      item.lv = constantItems.lv;
      item.en = constantItems.en;
      item.ru = constantItems.ru;

      items.push(item);
    });
    return items;
  }

  setPage(pageNumber: number) {
    this.currentPage = this.pages.filter(x => x.pageNumber == pageNumber)[0];
  }
}




export class Page {
  pageItems: PageItem[] = [];
  pageNumber: number;
}

export class PageItem {
  constant: string;
  lv: string;
  ru: string;
  en: string;
}
