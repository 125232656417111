<div class="call-to-action-container">
  <video id="vid" class="background-video" #videoplayer width="100%" height="100%" loop muted loading="lazy">
    <source src="/assets/videos/MEZOSLE_DEMO.mp4" type="video/mp4">
    Your browser does not support HTML video.
  </video>

  <div class="container action-contents">
    <div class="row">
      <div class="col-12">
        <p class="hero-text">Mežsole - elektroniska cirsmu un meža īpašumu izsoļu platforma</p>
        <p class="hero-explanatory-text">
          Vai zinājāt, ka 52% darījumu privātajā sektorā tiek noslēgti par cenu, kas ir zemāka par vidējo tirgus vērtību? Izmanto mūsu izsoļu platformu un neiekļaujies statistikā.
        </p>
        <a (click)="navigateToSection('contact')" class="hero-link">
          <button class="btn btn-hero hero-btn m-auto">PIETEIKTIES</button>
        </a>

      </div>
    </div>
  </div>

</div>

<div class="container">
  <div class="row container-padding" style="justify-content: center; align-items: center;">
    <div class="col-12 col-md-8">
      <p class="section-title">
        PAR MUMS
      </p>
      <p class="about">
        Mežsole tika veidota, lai atvieglotu meža īpašnieku ceļu cauri sarežģītam un emocionālam pārdošanas procesam. Mūsu galvenais mērķis ir padarīt to vienkāršu un izdevīgu. Mēs esam izstrādājuši pārbaudītu sistēmu, kas palīdz īpašniekiem uzzināt maksimālo viņu īpašuma vērtību un nodrošina, ka tas tiek pārdots par labāko iespējamo cenu. Mēs esam šeit, lai palīdzētu jums iegūt vairāk no sava īpašuma!
      </p>
      <!-- <p class="about">Mežizstrāde ir vārds, kas ievieš bailes un nepatiku gandrīz katrā meža īpašniekā Latvijā. Iemesls
        nav zināšanu
        trūkums, nedz arī dabas aizsardzība, bailes ir no meža izstrādātājiem.</p>
      <p class="about">
        "Mežsole" tika dibināta, lai pārstāvētu Latvijas meža īpašnieku intereses, izglītotu viņus un būt PIONIERIEM,
        kas panāk vārda SADARBĪBA pozitīvas emocijas.
      </p>
      <p class="about">
        Latvijā vairāk nekā 50% darījumu notiek stipri zem tirgus vidējās vērtības. Mēs vēlamies to mainīt, piedāvājot
        platformu, kur paši meža saimnieki izsola savu īpašumu.
      </p> -->
    </div>
    <div class="about-img-container d-flex">
      <div class="about-us" #aboutImgContainer>
      </div>
    </div>
  </div>
</div>

<div class="containter-fluid section-steps container-padding">
  <div class="d-flex">
    <div class="col-12">
      <p class="section-title text-center mb-0">
        PROCESS
      </p>
      <h2 class="text-center">Kā mēs strādājam?</h2>
      <p class="explanatory">Mēs sākam izsoli ar cenu, kas atbilst tirgus vērtībai, un tā ilgst 7 dienas. Pateicoties aktīvai uzņēmumu konkurencei, katrs nākamais solījums palielina peļņu meža īpašniekam.
      </p>
    </div>
  </div>
  <div class="container">

    <div class="row">
      <div class="col-11 col-md-6 col-lg-3 card-outer-container">
        <div class="card-container" #counter>
          <span class="count">01</span>
          <h5 class="text-center">Gatavojamies izsolei</h5>
          <p class="text-center">Sagatavojam izsoles objektu un veicam tā novērtēšanu</p>
        </div>
      </div>
      <div class="col-11 col-md-6 col-lg-3 card-outer-container">
        <div class="card-container" #counter>
          <span class="count">02</span>
          <h5 class="text-center">Dokumentu skaņošana</h5>
          <p class="text-center">Saskaņojam darbus ar Valsts iestādēm un trešajām personām (Ja nepieciešams)</p>
        </div>
      </div>
      <div class="col-11 col-md-6 col-lg-3 card-outer-container">
        <div class="card-container" #counter>
          <span class="count">03</span>
          <h5 class="text-center">Nosakām Izsoles sākuma cenu</h5>
          <p class="text-center">Tiek noteikta izsoles sākuma cena un izsole tiek pasludināta</p>
        </div>
      </div>
      <div class="col-11 col-md-6 col-lg-3 card-outer-container">
        <div class="card-container last" #counter>
          <span class="count">04</span>
          <h5 class="text-center">Organizējam darījumu</h5>
          <p class="text-center">Slēdzam līgumu ar izsoles uzvarētāju</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container section-guarantee" id="sectionGuarantee">
  <div class="row">
    <div class="col-12 mb-0 mb-md-3">
      <p class="section-title text-center mb-0">
        MEŽSOLES GARANTIJA
      </p>
      <h2 class="text-center">Kāpēc mums uzticēties?</h2>
      <p class="explanatory text-center">Jūsu panākumi ir mūsu prioritāte, sasniegsim to kopā!
      </p>
    </div>
    <div></div>
    <div class="justify-content-between d-flex">
      <div class="div"
        style="height: 100%;background-color: pink; padding-right: 300px; border-radius: 30px; background-size: cover;background-image: url('./../../../assets/images/Guarantee.jpg');">
      </div>
      <div class="row card-row">
        <div class="col-lg-4 col-md-6 col-10 trust-card-container">
          <div class="card" #cardElement>
            <span class="card-img-top icon-icon-hourglass"></span>
            <div class="card-body">
              <h5 class="card-title text-center">Klientu serviss</h5>
              <p class="card-text">Lai nostiprinātu savu pozīciju tirgū, strādājam arī brīvdienās</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-10 mb-3 trust-card-container">
          <div class="card" #cardElement>
            <span class="card-img-top icon-icon-recycle"></span>
            <div class="card-body">
              <h5 class="card-title text-center">Atgriezeniskā saite</h5>
              <p class="card-text">Ieklausāmies jūsu viedoklī un kļūstam labāki</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-10 mb-3 trust-card-container">
          <div class="card" #cardElement>
            <span class="card-img-top icon-icon-group"></span>
            <div class="card-body">
              <h5 class="card-title text-center">Komanda</h5>
              <p class="card-text">MEŽSOLE komandu raksturo zināšanas, prasmi, pieredzi, kas strādā kopā, lai
                sasniegtu Jūsu mērķi.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-10 mb-3 trust-card-container">
          <div class="card" #cardElement>
            <span class="icon-icon-arrow card-img-top"></span>
            <div class="card-body">
              <h5 class="card-title text-center">Efektivitāte</h5>
              <p class="card-text">No pirmā kontakta līdz darījumam 20 dienās</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-10 mb-3 trust-card-container">
          <div class="card" #cardElement>
            <span class="icon-icon-checklist card-img-top"></span>
            <div class="card-body">
              <h5 class="card-title text-center">Standartizācija</h5>
              <p class="card-text">Sadarbībā ar Valsts institucijām un privāto meža īpašnieku biedrībām,
                vēlamies izskaust negodīgu komercpraksi, lai aizstāvētu meža īpašnieka intereses.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-10 mb-3 trust-card-container">
          <div class="card" #cardElement>
            <span class="icon-icon-handshake card-img-top"><span class="path1"></span><span class="path2"></span><span
                class="path3"></span><span class="path4"></span><span class="path5"></span><span
                class="path6"></span><span class="path7"></span></span>
            <div class="card-body">
              <h5 class="card-title text-center">Ētika</h5>
              <p class="card-text">Pircēji - Zaļi domājoši uzņēmumi ar augstiem standartiem, kuri ir iestājušies
                par atbildīgu un ilgtspējīgu meža apsaimniekošanu.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid values-section">
  <div class="row">
    <div class="col-12">
      <div class="col-12 mb-0 mb-md-3">
        <p class="section-title-white text-center mb-0">
          MŪSU VĒRTĪBAS
        </p>
        <h2 class="text-center" style="max-width: 700px;">Lojalitāte, Inovācijas, Klientu orientācija, Attieksme</h2>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3 col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center highlighted-text"><strong>Lojalitāte</strong></h5>
              <p class="card-text text-center">Klientu intereses vienmēr būs nostādītas augstāk par savējām</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center highlighted-text"><strong>Inovācijas</strong></h5>
              <p class="card-text text-center">Sistēma, kas klientam atgādina par nepieciešamajiem saimnieciskajiem
                darbiem katru kalendāro gadu</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center highlighted-text"><strong>Klientu orientācija</strong></h5>
              <p class="card-text text-center">Fokuss uz klientu vajadzībām, to apmierināšana un uzticamība</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-md-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center highlighted-text"><strong>Attieksme</strong></h5>
              <p class="card-text text-center">Uzņēmuma kultūra, kas balstīta uz cieņu, godīgumu un atbildību</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="container section-review container-padding" id="sectionFeedBack">
  <div class="row">
    <div class="col-12">
      <p class="section-title text-center mb-0">
        NOSLĒGUŠIES DARĪJUMI
      </p>
      <h2 class="text-center">Meža īpašnieku stāsti</h2>
      <p class="explanatory explanatory text-center mb-5">Sasniedzām vērtības pieaugumu tavam kaimiņam
      </p>
    </div>
  </div>
  <div class="col-12 col-md-10 m-auto">
    <div class="row">
      <div class="col-9 col-lg-4 card-container-vid">
        <div class="card">
          <div class="img"></div>
          <div class="card-body">
            <h5 class="text-center mb-0">Juris no <span class="marker">Latgales</span></h5>
          </div>
        </div>
      </div>
      <div class="col-9 col-lg-4 card-container-vid">
        <div class="card">
          <div class="img"></div>
          <div class="card-body">
            <h5 class="text-center mb-0">Juris no <span class="marker">Latgales</span></h5>
          </div>
        </div>
      </div>
      <div class="col-9 col-lg-4 card-container-vid">
        <div class="card">
          <div class="img"></div>
          <div class="card-body">
            <h5 class="text-center mb-0">Juris no <span class="marker">Latgales</span></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div class="container section-review" id="BUJ">
  <div class="row">
    <div class="col-12">
      <p class="section-title text-center mb-0">
        BUJ
      </p>
      <h2 class="text-center">Biežāk uzdotie jautājumi</h2>
      <p class="explanatory explanatory text-center mb-5">Atrodi atbildes uz saviem jautājumiem
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div (click)="isCollapsed[0] = !isCollapsed[0]" class="d-flex justify-content-between colapse-containe">
        Kāpēc man izvēlēties Jūsu platformu? Es pats varu izveidot cenu aptauju starp sevis izvēlētiem uzņēmumiem.
        <span class="arrow" [ngClass]="{'rotate': isCollapsed[0]}"></span>
      </div>
      <div [@collapseAnimation]="isCollapsed[0] ? 'collapsed' : 'expanded'" class="collapsible-content">
        <ul>
          <li>Cenu aptaujā Jūs lūgsiet atsūtīt piedāvājumus un izvēlēsieties augstāko no saņemtajiem piedāvājumiem,
            neapzinoties produkta patieso vērtību un neradot konkurenci Jūs liedzat sev sasniegt augstāko iespējamo
            vērtību.</li>
          <li>Elektroniska tiešsaistes izsole ir vienīgais zināmais, kā arī ienesīgākais veids, kā realizēt cirsmu vai
            meža īpašumu, jo dinamiska konkurence starp pircējiem palielina piedāvātās summas.</li>
        </ul>
      </div>
      <div (click)="isCollapsed[1] = !isCollapsed[1]" class="d-flex justify-content-between colapse-containe">
        Kādi uzņēmumi ir reģistrējušies jūsu platformā kā potenciālie pircēji?
        <span class="arrow" [ngClass]="{'rotate': isCollapsed[1]}"></span>
      </div>
      <div [@collapseAnimation]="isCollapsed[1] ? 'collapsed' : 'expanded'" class="collapsible-content">
        Mūsu platformā ir reģistrējušies uzņēmumi no mežsaimniecības, kokapstrādes, celulozes, Enerģētiskas ražošanas
        nozarēm, kā arī citi uzņēmumi, kuriem nepieciešami meža resursi.
      </div>
      <div (click)="isCollapsed[2] = !isCollapsed[2]" class="d-flex justify-content-between colapse-containe">
        Vai man kā pārdevējam ir jāmaksā par platformas izmantošanu?
        <span class="arrow" [ngClass]="{'rotate': isCollapsed[2]}"></span>
      </div>
      <div [@collapseAnimation]="isCollapsed[2] ? 'collapsed' : 'expanded'" class="collapsible-content">
        Mūsu mērķis ir nodrošināt jums labāko pārdošanas pieredzi. Darījums apliekas ar komisijas maksu tikai ja noritēs
        veiksmīgi. Komisijas maksu pārskaita izsoles uzvarētājs.
      </div>
      <div (click)="isCollapsed[3] = !isCollapsed[3]" class="d-flex justify-content-between colapse-containe">
        Vai ir iespējama negodīga konkurence un aizmugures vienošanās starp uzņēmumiem?
        <span class="arrow" [ngClass]="{'rotate': isCollapsed[3]}"></span>
      </div>
      <div [@collapseAnimation]="isCollapsed[3] ? 'collapsed' : 'expanded'" class="collapsible-content">
        Mēs pilnībā izslēdzam šo stereotipu, jo nav iespējams vienoties starp simtiem izsoles dalībnieku kuriem
        nepieciešami koksnes resursi tālākai pārstrādei un uzņēmumu darbībai.
      </div>
      <div (click)="isCollapsed[4] = !isCollapsed[4]" class="d-flex justify-content-between colapse-containe">
        Cik ilgi vidēji aizņem meža īpašuma pārdošanas process jūsu platformā?
        <span class="arrow" [ngClass]="{'rotate': isCollapsed[4]}"></span>
      </div>
      <div [@collapseAnimation]="isCollapsed[4] ? 'collapsed' : 'expanded'" class="collapsible-content">
        Vidēji pārdošanas process aizņem no 2 līdz 4 nedēļām, atkarībā no īpašuma specifikācijas.
      </div>
      <div (click)="isCollapsed[5] = !isCollapsed[5]" class="d-flex justify-content-between colapse-containe">
        Kā es varu pārliecināties, ka saņemšu maksājumu par savu īpašumu?
        <span class="arrow" [ngClass]="{'rotate': isCollapsed[5]}"></span>
      </div>
      <div [@collapseAnimation]="isCollapsed[5] ? 'collapsed' : 'expanded'" class="collapsible-content">
        Mēs strādājam tikai ar uzticamiem un pārbaudītiem uzņēmumiem. Turklāt mūsu platforma nodrošina drošu darījumu
        procesu, kurā samaksu par Cirsmu Jūs saņemsiet ar 100% priekšapmaksu vai realizējot Meža īpašumu Jūs saņemsiet
        samaksu pirms īpašumtiesību maiņas.
      </div>

      <div (click)="isCollapsed[6] = !isCollapsed[6]" class="d-flex justify-content-between colapse-containe">
        Kā jūs nodrošināt, ka uzņēmumi piedāvā reālas un godīgas cenas?
        <span class="arrow" [ngClass]="{'rotate': isCollapsed[6]}"></span>
      </div>
      <div [@collapseAnimation]="isCollapsed[6] ? 'collapsed' : 'expanded'" class="collapsible-content">
        Izsoles sākuma cena tiek noteikta tirgū vidējā un katrs nākošais solījums par Jūsu izsoles objektu ir papildus
        peļņa.
      </div>
    </div>
  </div>
</div>

<div class="container section-contact container-padding" id="contact">
  <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="col-12 col-md-10 m-auto">
        <div class="row justify-content-between">
          <div class="col-4 woman-calling d-none d-lg-block ">
          </div>
          <div class="col-12 col-lg-7">
            <p class="section-title text-center mb-0">
              Sazinies ar mums
            </p>
            <h2 class="text-center text-white mb-5">Atstāj mums ziņu, lai varam ar tevi sazināties.</h2>

            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <input type="text" placeholder="Vārds*" class="form-control" id="name" formControlName="name"
                    [class.is-invalid]="!contactForm.controls['name'].valid && contactForm.controls['name'].touched">
                  <span class="text-danger"
                    *ngIf="!contactForm.controls['name'].valid && contactForm.controls['name'].touched">
                    Vārdam jābūt aizpildītam!
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <!-- <label for="phone">Tālruņa nr<span class="text-danger">*</span></label> -->
                  <input placeholder="Tālruņa numurs*" type="text" class="form-control" id="phone"
                    formControlName="phone"
                    [class.is-invalid]="!contactForm.controls['phone'].valid && contactForm.controls['phone'].touched">
                  <span class="text-danger"
                    *ngIf="!contactForm.controls['phone'].valid && contactForm.controls['phone'].touched">
                    Tālrunim jābūt aizpildītam!
                  </span>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <textarea type="text" placeholder="Jūsu ziņa*" class="form-control" id="message"
                    formControlName="message"
                    [class.is-invalid]="!contactForm.controls['message'].valid && contactForm.controls['message'].touched">
                  </textarea>
                  <span class="text-danger"
                    *ngIf="!contactForm.controls['message'].valid && contactForm.controls['message'].touched">
                    Lūdzu ievadiet jūsu ziņu!
                  </span>

                </div>
              </div>
              <ng-container *ngIf="!contactFormCalled; else contacted">
                <div class="col-12">
                  <ng-container *ngIf="!contactFormIsLoading; else loading">
                    <button type="submit" class="btn contact-btn">
                      Pieteikties
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <ng-template #contacted>
              <div class="w-100 mt-3">
                <h4 class="text-center">
                  Paldies par pieteikumu, mēs drīz ar jums sazināsimies! <button type="button" class="btn btn-primary"
                    (click)="contactFormCalled=true">Sūtīt atkārtoti</button>
                </h4>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>
<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>

<ng-template #loadingProperties>
  <div>loading properties</div>
</ng-template>
<app-toaster-container></app-toaster-container>
