import { FileDto } from './../../models/all.models';
import { AuctionService } from './../../auction/auction.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';;
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
    id;
    @Input()
    requiredFileType:string;

    fileName = '';
    uploadProgress:number;
    uploadSub: Subscription;

    files: FileDto[] = [];

    constructor(private http: HttpClient,  private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private auctionService: AuctionService) {}

    ngOnInit(): void {
      this.id = this.activatedRoute.snapshot.params["id"];
      this.getFiles();
    }

    getFiles() {
      this.auctionService.getPropertyById(this.id).subscribe(x => this.files = x.files)
    }
    onFileSelected(event) {
        const file:File = event.target.files[0];
    
        if (file) {
            this.fileName = file.name;
            const formData = new FormData();
            formData.append('propertyId', this.id);
            formData.append('file', file, this.fileName);

            const upload$ = this.http.post("/api/File/UploadFiles", formData,  {
              reportProgress: true,
              observe: 'events'
          })
          .pipe(
              finalize(() => {
                this.reset();
                this.getFiles();
              })
          );
        
          this.uploadSub = upload$.subscribe(event => {
            if (event.type == HttpEventType.UploadProgress) {
              this.uploadProgress = Math.round(100 * (event.loaded / event.total));
            }
          })
      }
  }



  cancelUpload() {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  down(id: number) {
    const upload$ = this.http.get("/api/File/DownloadFile/" + id , { responseType: "arraybuffer" });
    upload$.subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    })
  }

  delete(id: number) {
    const del$ = this.http.delete("/api/File/" + id)
    .pipe(
      finalize(() => this.getFiles())
    )
    del$.subscribe();
  }
}
