<table class="w-100 table">
  <tbody>
    <tr>
      <td class="text-left">Regions</td>
      <td class="text-right">{{property.region.name}}</td>
    </tr>
    <tr>
      <td class="text-left">Novads</td>
      <td class="text-right">{{property.county.name}}</td>
    </tr>
    <tr>
      <td class="text-left">Cirtes izpildes veids</td>
      <td class="text-right">{{property.propertyExecutionType.name}}</td>
    </tr>
    <tr>
      <td class="text-left">Cirsmas kopplatība</td>
      <td class="text-right">{{property.propertyArea}}</td>
    </tr>
    <tr>
      <td class="text-left">Attālums līdz AGK</td>
      <td class="text-right">{{property.distanceToAGK}}</td>
    </tr>
    <tr>
      <td class="text-left">Izsoles sākuma cena</td>
      <td class="text-right">{{property.startingPrice}}</td>
    </tr>
    <tr>
      <td class="text-left">Izsoles minimālais solis</td>
      <td class="text-right">{{property.minimalBiddingStep}}</td>
    </tr>
    <tr>
      <td class="text-left">Izsoles maksimālais solis solis</td>
      <td class="text-right">{{property.maximalBiddingStep}}</td>
    </tr>
    <tr>
      <td class="text-left">Apmaksas termiņš</td>
      <td class="text-right">{{property.duePayDateTime |  date:'dd/MM/yyyy'}}</td>
    </tr>
    <tr>
      <td class="text-left">Meža izstrādes termiņš</td>
      <td class="text-right">{{property.allowedToWorkTillDateTime |  date:'dd/MM/yyyy'}}</td>
    </tr>
    <tr>
      <td class="text-left">Izsole noslēdzas</td>
      <td class="text-right">{{property.auctionEnds |  date:'dd/MM/yyyy MM:hh'}}</td>
    </tr>

  </tbody>
</table>