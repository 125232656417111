<h2>Tulkojumu pārvaldība</h2>
<!-- <div class="container-fluid"> -->

  <!-- <div class="col-12"> -->

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Konstante</th>
          <th scope="col">Latviešu</th>
          <th scope="col"></th>
          <th scope="col">Angļu</th>
          <th scope="col"></th>
          <th scope="col">Krievu</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
    <ng-container *ngIf="currentPage?.pageItems?.length">
      <ng-container *ngFor="let pageItem of currentPage.pageItems">

        
          <tr>
            <td>{{pageItem.constant}}</td>            
            <td>{{pageItem.lv}}</td>
            <td><i class="fa fa-edit fa-3" (click)="openTranslationUpdateModal(pageItem.lv, 'lv', pageItem.constant)"></i></td>
            <td>{{pageItem.en}}</td>
            <td><i class="fa fa-edit fa-3" (click)="openTranslationUpdateModal(pageItem.en, 'en', pageItem.constant)"></i></td>
            <td>{{pageItem.ru}}</td>
            <td><i class="fa fa-edit fa-3" (click)="openTranslationUpdateModal(pageItem.ru, 'ru', pageItem.constant)"></i></td>
          </tr>
        <!-- <div>
          <div class="row">
            <div class="col-12"></div>
          <div class="col-3">
            {{pageItem.constant}}
          </div>

          <div class="col-3">
            {{pageItem.lv}} <button (click)="openTranslationUpdateModal(pageItem.lv, 'lv', pageItem.constant)">labot</button>
          </div>

          <div class="col-3">
            {{pageItem.en}}
          </div>

          <div class="col-3">
            {{pageItem.ru}}
          </div>
        </div>
      </div> -->
      </ng-container>
    </ng-container>
  </tbody>
  </table>
<!-- </div> -->
<!-- </div> -->
<ng-container *ngIf="pages?.length">
  <div class="pagination d-flex">
    <span>&laquo;</span>
    <ng-container *ngFor="let page of pages">
      <button class="mr-1" (click)="setPage(page.pageNumber)"
        [ngClass]="currentPage?.pageNumber === page.pageNumber ? 'active' : ''">
        {{page.pageNumber}}
      </button>
    </ng-container>
    <span>&raquo;</span>
  </div>
</ng-container>