import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { View360Options, EquirectProjection } from "@egjs/ngx-view360";

@Component({
  selector: 'app-wide-angle-viewer',
  templateUrl: './wide-angle-viewer.component.html',
  styleUrls: ['./wide-angle-viewer.component.scss']
})
export class WideAngleViewerComponent implements OnInit {
  isLoading = true;

  uploadSub: Subscription;
  url: string = '';
  options: Partial<View360Options> = {
    projection: new EquirectProjection({
      src: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1025px-Cat03.jpg",
      // src: this.url
    })
  }
  constructor(
    private http: HttpClient, 
  ) { }

  ngOnInit() {
    this.getImage();
  }

  getImage() {
    const upload$ = this.http.get("/api/File/CountyImage/"+ 1, { responseType: "arraybuffer" });
    upload$.pipe(finalize(() => {
      this.isLoading = false
    }
     
     
     )).subscribe(data => {
      
      var arrayBufferView = new Uint8Array( data );
      var blob = new Blob( [ arrayBufferView ], { type: "image/png" } );
      var urlCreator = window.URL || (window as any).webkitURL;      
      this.url = urlCreator.createObjectURL( blob );


      this.options = {
        ...this.options,
        projection: new EquirectProjection({
          src: this.url
        })
      };
    })
  }
}
