export class UserRegistrationModel {
    firstName: string;
    surname: string;
    authorityType: number;

    username: string;
    password: string;
    passwordRepeat: string;
    email: string;

    phoneNumber: string;

    companyRegistrationCountry: string;
    companyRegistrationNumber: string;
    companyLegalAddress: string;
    companyVatNumber: string;
    companyName: string;
    authorityTypeOther: string;

    public constructor(init?: Partial<UserRegistrationModel>) {
        Object.assign(this, init);
        if (init && init.authorityType) {
            this.authorityType = +init.authorityType;
        }
    }
}

export class UserLogin{
    userName: string;
    password: string;

    public constructor(init?: Partial<UserLogin>) {
        Object.assign(this, init);
    }
}

export class SubscribedAuctionNotification{
    AutcionEndDate: Date;
    CurrentPrice : number;
    NotificaionHeader: string;
    NotificaionLinkUrl: string;
    NotificaionMessage: string;
    StartingPrice: number;
    PropertyId: number;

    public constructor(init?: Partial<UserLogin>) {
        Object.assign(this, init);
    }
}

export class ParticipatingAuctionNotification{
    AutcionEndDate: Date;
    CurrentPrice : number;
    UserMaxBid : number;
    NotificaionHeader: string;
    NotificaionLinkUrl: string;
    NotificaionMessage: string;

    public constructor(init?: Partial<UserLogin>) {
        Object.assign(this, init);
    }
} 

export class NewAuctionNofitication{
    AutcionStartDate: Date;
    StartingPrice: number;
    NotificaionHeader: string;
    NotificaionLinkUrl: string;
    NotificaionMessage: string;
    Id: number;

    public constructor(init?: Partial<UserLogin>) {
        Object.assign(this, init);
    }
} 

export class DecodedJWTUser {
    role: string; 
    userId: string;
    userName: string;
    email: string;
    public constructor(init?: Partial<DecodedJWTUser>) {
        Object.assign(this, init);
    }
}

export class ApplicationUser {
    role: string[] = []; 
    userId: string;
    userName: string;
    email: string;
    public constructor(init?: Partial<DecodedJWTUser>) {
        Object.assign(this, init); 
    }
}

// const userRoles = 'CanCreateNewBusiness' | 'CanManipulateTranslations';