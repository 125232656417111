<div class="row mt-3">
  <div class="col-12">
    <div class="row">

      <div class="col-8"><h2>Koksnes tipu saraksts</h2></div>
      <div class="col-4 text-right">
        <button class="btn btn-primary" (click)="openNewRegionModal()">Pievienot jaunu koksnes tipu</button>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nosaukums</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!isLoading; else loading">
          <tr *ngFor="let prop of treeTypes">
            <td>{{prop.id}}</td>
            <td>{{prop.name}}</td>
            <td>
              <a [routerLink]="'/admin/region/' + prop.id">Pārvaldīt</a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #loading>
  <tr>
    <td>
      <h4 class="text-center">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </h4>
  </td>
  </tr>
</ng-template>