<div class="w-100">
    <table class="table text-center">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Suga</th>
                <th scope="col">Krāja m&#179;</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="treeQuantities.length > 0">
                <tr *ngFor="let treeQuantity of treeQuantities; let i = index">
                    <th scope="row">{{i + 1}}</th>
                    <td>{{treeQuantity.treeType.name}}</td>
                    <td>{{treeQuantity.quantity}}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>