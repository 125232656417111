import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Unauthorized',
  templateUrl: './Unauthorized.component.html',
  styleUrls: ['./Unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
