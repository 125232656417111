import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-did-you-get-here',
  templateUrl: './how-did-you-get-here.component.html',
  styleUrls: ['./how-did-you-get-here.component.css']
})
export class HowDidYouGetHereComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
