import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesComponent } from './articles.component';
import { RouterModule, Routes } from '@angular/router';
import { ArticleService } from '../admin/posts/article.service';
import { LoadingModule } from '../loading/loading.module';
import { ArticleComponent } from './article/article.component';
import { AllArticlesComponent } from './all-articles/all-articles.component';

const routes: Routes = [
  { path: 'articles', component: ArticlesComponent,
    children: [
      {path:'', component: AllArticlesComponent},
      {path: ':id', component: ArticleComponent}
  ]
  },
  
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    LoadingModule
  ],
  declarations: [
    ArticlesComponent,
    ArticleComponent,
    AllArticlesComponent
  ],
  exports: [
    RouterModule,
    ArticlesComponent
  ],
  providers: [ArticleService]
})
export class ArticlesModule { }
