<h2>Parakstāmie līgumi</h2>
<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Lietotājs</th>
      <th scope="col">Uzņēmums</th>
      <th scope="col">E-Pasta adrese</th>
      <th scope="col">Līgums</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="!isLoading; else loading">
      <ng-container *ngFor="let contract of unsignedContracts">
        <tr app-single-signable-contract [listChanged]="listChanged" [contract]="contract">
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>